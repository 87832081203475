export const waterABI = [
  {
    "constant": true,
    "inputs":[],
    "name":"isItRaining",
    "outputs":[{"name":"raining","type":"uint256"}],
    "type":"function"
  },
  {
    "constant": true,
    "inputs":[],
    "name":"timeUntilStorm",
    "outputs":[{"name":"tUntilStorm","type":"uint256"}],
    "type":"function"
  },
  {
    "constant": true,
    "inputs":[],
    "name":"getLakeVolume",
    "outputs":[{"name":"lakeVolume","type":"uint256"}],
    "type":"function"
  },
  {
    "constant": true,
    "inputs":[],
    "name":"getLakeTakePrice",
    "outputs":[{"name":"lakeTakePrice","type":"uint256"}],
    "type":"function"
  },
  {
    "constant": true,
    "inputs":[],
    "name":"takeWaterFromLake",
    "outputs":[{"name":"didTake","type":"uint256"}],
    "type":"function"
  },
  {
    "constant": true,
    "inputs":[],
    "name":"checkCollectedRain",
    "outputs":[{"name":"collectedRain","type":"uint256"}],
    "type":"function"
  },
  {
    "constant": true,
    "inputs":[],
    "name":"placeRainCollector",
    "outputs":[{"name":"placed","type":"uint256"}],
    "type":"function"
  },
  {
    "constant": true,
    "inputs":[],
    "name":"pickupRainCollector",
    "outputs":[{"name":"placed","type":"uint256"}],
    "type":"function"
  },


];
