export const saltABI = [
  {
    "constant": true,
    "inputs":[],
    "name":"beginMining",
    "outputs":[{"name":"rightNow","type":"uint256"}],
    "type":"function"
  },
  {
    "constant": true,
    "inputs":[],
    "name":"claim",
    "outputs":[{"name":"claimQty","type":"uint256"}],
    "type":"function"
  },
  {
    "constant": true,
    "inputs":[{"name": "account", "type":"address"}],
    "name":"accumulated",
    "outputs":[{"name":"totalAccumulated","type":"uint256"}],
    "type":"function"
  },


];