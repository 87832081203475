// Author: Ianoda aka Maxus -- maxus.blog

import React from 'react';
import Web3 from 'web3'; 

import { InputView } from './inputview.js'
import { formatTokenBalance, searchAddress, fromTokenNameTo, fromTokenNameToDecimals, fromTokenNameToAddress } from '../tools/tokenUtilities.js'

import { chainMap, enforceChain } from '../tools/ChainTools.js';
import { contractConfigs, FarmgodCore, standardTokens, lpConfigs } from './farmgod-core.js';
import { Converter } from './gdeliConverter.js'
import { PenergConverter } from './penerg.js'


const CHEF = contractConfigs["chef"]["address"]
 



export const NationalBank = (props) => {

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  // this is a reference to the input field
  const theInputRef = React.createRef();

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [isTargetEnerg, setIsTargetEnerg] = React.useState(true);
  const [isTargetPenerg, setIsTargetPenerg] = React.useState(false);

  // this state manages the display of the input overlay
  const [theInputTOGGLE, setTheInputTOGGLE] = React.useState(false);

  // this function manages the toggling of theInputTOGGLE state
  const toggleInput = () => {
    if (theInputTOGGLE) {
      setTheInputTOGGLE(false)
    } else {
      setTheInputTOGGLE(true)
    }
  }

  // this state manages the intent of the input overlay
  // it should be set to false initially,
  // and then set to either "add" or "remove"
  const [theInputINTENT, setTheInputINTENT] = React.useState(false);

  // this is the web3 instance used throughout the dApp
  var web3 = props.web3


//  END SCRIPT

  var converter = new Converter(web3, theInputRef)
  var gdeli = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["gdeli"]["address"])
  var gdeliLP_spiritv1 = new web3.eth.Contract(contractConfigs["spiritv1LP"]["abi"], FarmgodCore["ftmGdeliSpirLP"]["address"])
  var spiritv2Router = new web3.eth.Contract(lpConfigs["spiritv2LP"]["abi"], lpConfigs["spiritv2LP"]["router"])
  var energ = new web3.eth.Contract(contractConfigs["energ"]["abi"], FarmgodCore["energ"]["address"])
  var mgdeli = new web3.eth.Contract(contractConfigs["mgdeli"]["abi"], FarmgodCore["mgdeli"]["address"])
  var penerg = new web3.eth.Contract(contractConfigs["penerg"]["abi"], FarmgodCore["penerg"]["address"])

  var acre = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["acre"]["address"])
  var ugu = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["ugu"]["address"])
  var liggies = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["liggies"]["address"])
  var animal = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["animal"]["address"])

  var wheat = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["wheat"]["address"])
  var cane = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["sugarcane"]["address"])
  var seed = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["gseed"]["address"])

  var water = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["water"]["address"])
  var salt = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["salt"]["address"])
  var yomopu = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["yomopu"]["address"])

  var penergConverter = new PenergConverter(web3, theInputRef)

  const [balanceOfGDeli,setBalanceOfGDeli] = React.useState(0);
  const [balanceOfENERg,setBalanceOfENERg] = React.useState(0);
  const [balanceOfMGDeli,setBalanceOfMGDeli] = React.useState(0);
  const [balanceOfpENERg,setBalanceOfpENERg] = React.useState(0);

  const [balanceOfAcre,setBalanceOfAcre] = React.useState(0);
  const [balanceOfUgu,setBalanceOfUgu] = React.useState(0);
  const [balanceOfLiggies,setBalanceOfLiggies] = React.useState(0);
  const [balanceOfAnimal,setBalanceOfAnimal] = React.useState(0);

  const [dbalanceOfGDeli,setdBalanceOfGDeli] = React.useState("");
  const [dbalanceOfENERg,setdBalanceOfENERg] = React.useState("");
  const [dbalanceOfMGDeli,setdBalanceOfMGDeli] = React.useState("");
  const [dbalanceOfpENERg,setdBalanceOfpENERg] = React.useState("");

  const [dbalanceOfAcre,setdBalanceOfAcre] = React.useState("");
  const [dbalanceOfUgu,setdBalanceOfUgu] = React.useState("");
  const [dbalanceOfLiggies,setdBalanceOfLiggies] = React.useState("");
  const [dbalanceOfAnimal,setdBalanceOfAnimal] = React.useState("");


  React.useEffect( () => {
    setdBalanceOfGDeli(formatTokenBalance(9,balanceOfGDeli))
  }, [balanceOfGDeli])
  React.useEffect( () => {
    setdBalanceOfENERg(formatTokenBalance(9,balanceOfENERg))
  }, [balanceOfENERg])
  React.useEffect( () => {
    setdBalanceOfMGDeli(formatTokenBalance(9,balanceOfMGDeli))
  }, [balanceOfMGDeli])
  React.useEffect( () => {
    setdBalanceOfpENERg(formatTokenBalance(9,balanceOfpENERg))
  }, [balanceOfpENERg])
  React.useEffect( () => {
    setdBalanceOfAcre(formatTokenBalance(18,balanceOfAcre))
  }, [balanceOfAcre])
  React.useEffect( () => {
    setdBalanceOfUgu(formatTokenBalance(18,balanceOfUgu))
  }, [balanceOfUgu])
  React.useEffect( () => {
    setdBalanceOfLiggies(formatTokenBalance(18,balanceOfLiggies))
  }, [balanceOfLiggies])
  React.useEffect( () => {
    setdBalanceOfAnimal(formatTokenBalance(18,balanceOfAnimal))
  }, [balanceOfAnimal])
 


  const getBalance = (token) => {
    if (token == 0) {
      gdeli.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfGDeli(res))
    } else if (token == 1) {
      energ.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfENERg(res))
    } else if (token == 2) {
      mgdeli.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfMGDeli(res))
    } else if (token == 3) {
      penerg.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfpENERg(res))
    } else if (token == 4) {
      acre.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfAcre(res))
    } else if (token == 5) {
      ugu.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfUgu(res))
    } else if (token == 6) {
      liggies.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfLiggies(res))
    } else if (token == 7) {
      animal.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfAnimal(res))
    } 
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's wallet balance of the staking token
  const [balanceOfTarget, setBalanceOfTarget] = React.useState(0);
  const smartSetBalanceOfTarget = (balance) => {
    console.log(balance)
    setBalanceOfTarget(balance)
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's allowance (to the CHEF) of the staking token
  const [allowanceOfTarget, setAllowanceOfTarget] = React.useState(0);
  const smartSetAllowanceOfTarget = (allowance) => {
    console.log(allowance)
    setAllowanceOfTarget(allowance)
  }

  // onClick function for approving a given amount of the staking token
  const onClick_Approve = () => {
    setIsPending(true)
    if (!isTargetPenerg) {
      converter.triggerApproval(
      isTargetEnerg, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
    } else {
      let convertingENERgTopENERg = (theInputINTENT == "add") ? true : false;
      penergConverter.triggerApproval(
      convertingENERgTopENERg, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
    }
    
  }

  // -- end of Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  // onClick function factory for building appropriate input overlay
  const openInputViewAndDoStuff = (isEnerg, intent = "add", isPenergConversion = false) => {
    if (!isPenergConversion) {
      return () => {

        toggleInput()
        setIsTargetEnerg(isEnerg)
        setIsTargetPenerg(false)
        setTheInputINTENT(intent)
        smartSetBalanceOfTarget(balanceOfGDeli)
        converter.checkAllowance(isEnerg, (allowance) => smartSetAllowanceOfTarget(allowance))

      }
    } else {
        return () => {

          toggleInput()
          setIsTargetEnerg(false)
          setIsTargetPenerg(true)
          setTheInputINTENT(intent)
          smartSetBalanceOfTarget((intent == "add") ? balanceOfENERg : balanceOfpENERg)
          penergConverter.checkAllowance((intent == "add") ? true : false, (allowance) => smartSetAllowanceOfTarget(allowance))
        }
    }
    

  }

   const setInputRefToMAX = () => {
    if (theInputINTENT == "remove") {
      theInputRef.current.value = formatTokenBalance(9, balanceOfpENERg, false)
    } else {
      theInputRef.current.value = formatTokenBalance(9, balanceOfTarget, false)
    }
    
  }

  // onClick function for depositing
  const triggerDeposit = () => {
    setIsPending(true)
    if (!isTargetPenerg) {
      converter.depositAmount(
      isTargetEnerg,
      () => {
        setIsPending(false)
        toggleInput()
        getBalance(0)
        getBalance((isTargetEnerg) ? 1 : 2)
        }    
      )
    } else {
      penergConverter.depositAmount(
      () => {
        setIsPending(false)
        toggleInput()
        getBalance(1)
        getBalance(3)
        }    
      )
    }
    
  }


  const [lpReserve0, setLpReserve0] = React.useState(0);
  const [lpReserve1, setLpReserve1] = React.useState(0);
  const [dlpReserve0, setdLpReserve0] = React.useState("");
  const [dlpReserve1, setdLpReserve1] = React.useState("");

  const [lpReserve__ACRE_DAI0, setLpReserve__ACRE_DAI0] = React.useState(0);
  const [lpReserve__ACRE_DAI1, setLpReserve__ACRE_DAI1] = React.useState(0);
  const [dlpReserve__ACRE_DAI0, setdLpReserve__ACRE_DAI0] = React.useState("");
  const [dlpReserve__ACRE_DAI1, setdLpReserve__ACRE_DAI1] = React.useState("");

  const [amountOut__ACRE_DAI, setAmountOut__ACRE_DAI] = React.useState(0)
  const [damountOut__ACRE_DAI, setdAmountOut__ACRE_DAI] = React.useState("")

  const [lpReserve__ACRE_ANIMAL0, setLpReserve__ACRE_ANIMAL0] = React.useState(0);
  const [lpReserve__ACRE_ANIMAL1, setLpReserve__ACRE_ANIMAL1] = React.useState(0);
  const [dlpReserve__ACRE_ANIMAL0, setdLpReserve__ACRE_ANIMAL0] = React.useState("");
  const [dlpReserve__ACRE_ANIMAL1, setdLpReserve__ACRE_ANIMAL1] = React.useState("");

  const [amountOut__ACRE_ANIMAL, setAmountOut__ACRE_ANIMAL] = React.useState(0)
  const [damountOut__ACRE_ANIMAL, setdAmountOut__ACRE_ANIMAL] = React.useState("")

  const [lpReserve__ACRE_UGU0, setLpReserve__ACRE_UGU0] = React.useState(0);
  const [lpReserve__ACRE_UGU1, setLpReserve__ACRE_UGU1] = React.useState(0);
  const [dlpReserve__ACRE_UGU0, setdLpReserve__ACRE_UGU0] = React.useState("");
  const [dlpReserve__ACRE_UGU1, setdLpReserve__ACRE_UGU1] = React.useState("");

  const [amountOut__ACRE_UGU, setAmountOut__ACRE_UGU] = React.useState(0)
  const [damountOut__ACRE_UGU, setdAmountOut__ACRE_UGU] = React.useState("")

  const [gDeliImpliedPrice, setGDeliImpliedPrice] = React.useState()

  React.useEffect( () => {
    setdLpReserve0(formatTokenBalance(18,lpReserve0))
  }, [lpReserve0])
  React.useEffect( () => {
    setdLpReserve1(formatTokenBalance(9,lpReserve1))
  }, [lpReserve1])
  React.useEffect( () => {
    setdLpReserve__ACRE_DAI0(formatTokenBalance(18,lpReserve__ACRE_DAI0))
  }, [lpReserve__ACRE_DAI0])
  React.useEffect( () => {
    setdLpReserve__ACRE_DAI1(formatTokenBalance(18,lpReserve__ACRE_DAI1))
  }, [lpReserve__ACRE_DAI1])
  React.useEffect( () => {
    setdAmountOut__ACRE_DAI(formatTokenBalance(18,amountOut__ACRE_DAI))
  }, [amountOut__ACRE_DAI])
  React.useEffect( () => {
    setdLpReserve__ACRE_ANIMAL0(formatTokenBalance(18,lpReserve__ACRE_ANIMAL0))
  }, [lpReserve__ACRE_ANIMAL0])
  React.useEffect( () => {
    setdLpReserve__ACRE_ANIMAL1(formatTokenBalance(18,lpReserve__ACRE_ANIMAL1))
  }, [lpReserve__ACRE_ANIMAL1])
  React.useEffect( () => {
    setdAmountOut__ACRE_ANIMAL(formatTokenBalance(18,amountOut__ACRE_ANIMAL))
  }, [amountOut__ACRE_ANIMAL])
 React.useEffect( () => {
    setdLpReserve__ACRE_UGU0(formatTokenBalance(18,lpReserve__ACRE_UGU0))
  }, [lpReserve__ACRE_UGU0])
  React.useEffect( () => {
    setdLpReserve__ACRE_UGU1(formatTokenBalance(18,lpReserve__ACRE_UGU1))
  }, [lpReserve__ACRE_UGU1])
  React.useEffect( () => {
    setdAmountOut__ACRE_UGU(formatTokenBalance(18,amountOut__ACRE_UGU))
  }, [amountOut__ACRE_UGU])

  const [lpReserve__ACRE_MGDELI0, setLpReserve__ACRE_MGDELI0] = React.useState(0);
  const [lpReserve__ACRE_MGDELI1, setLpReserve__ACRE_MGDELI1] = React.useState(0);
  const [dlpReserve__ACRE_MGDELI0, setdLpReserve__ACRE_MGDELI0] = React.useState("");
  const [dlpReserve__ACRE_MGDELI1, setdLpReserve__ACRE_MGDELI1] = React.useState("");

  const [amountOut__ACRE_MGDELI, setAmountOut__ACRE_MGDELI] = React.useState(0)
  const [damountOut__ACRE_MGDELI, setdAmountOut__ACRE_MGDELI] = React.useState("")

 React.useEffect( () => {
    setdLpReserve__ACRE_MGDELI0(formatTokenBalance(18,lpReserve__ACRE_MGDELI0))
  }, [lpReserve__ACRE_MGDELI0])
  React.useEffect( () => {
    setdLpReserve__ACRE_MGDELI1(formatTokenBalance(9,lpReserve__ACRE_MGDELI1))
  }, [lpReserve__ACRE_MGDELI1])
  React.useEffect( () => {
    setdAmountOut__ACRE_MGDELI(formatTokenBalance(9,amountOut__ACRE_MGDELI))
  }, [amountOut__ACRE_MGDELI])
const [lpReserve__ANIMAL_LIGGIES0, setLpReserve__ANIMAL_LIGGIES0] = React.useState(0);
  const [lpReserve__ANIMAL_LIGGIES1, setLpReserve__ANIMAL_LIGGIES1] = React.useState(0);
  const [dlpReserve__ANIMAL_LIGGIES0, setdLpReserve__ANIMAL_LIGGIES0] = React.useState("");
  const [dlpReserve__ANIMAL_LIGGIES1, setdLpReserve__ANIMAL_LIGGIES1] = React.useState("");

  const [amountOut__ANIMAL_LIGGIES, setAmountOut__ANIMAL_LIGGIES] = React.useState(0)
  const [damountOut__ANIMAL_LIGGIES, setdAmountOut__ANIMAL_LIGGIES] = React.useState("")

 React.useEffect( () => {
    setdLpReserve__ANIMAL_LIGGIES0(formatTokenBalance(18,lpReserve__ANIMAL_LIGGIES0))
  }, [lpReserve__ANIMAL_LIGGIES0])
  React.useEffect( () => {
    setdLpReserve__ANIMAL_LIGGIES1(formatTokenBalance(18,lpReserve__ANIMAL_LIGGIES1))
  }, [lpReserve__ANIMAL_LIGGIES1])
  React.useEffect( () => {
    setdAmountOut__ANIMAL_LIGGIES(formatTokenBalance(18,amountOut__ANIMAL_LIGGIES))
  }, [amountOut__ANIMAL_LIGGIES])

  const [lpReserve__ANIMAL_SPIRIT0, setLpReserve__ANIMAL_SPIRIT0] = React.useState(0);
  const [lpReserve__ANIMAL_SPIRIT1, setLpReserve__ANIMAL_SPIRIT1] = React.useState(0);
  const [dlpReserve__ANIMAL_SPIRIT0, setdLpReserve__ANIMAL_SPIRIT0] = React.useState("");
  const [dlpReserve__ANIMAL_SPIRIT1, setdLpReserve__ANIMAL_SPIRIT1] = React.useState("");

  const [amountOut__ANIMAL_SPIRIT, setAmountOut__ANIMAL_SPIRIT] = React.useState(0)
  const [damountOut__ANIMAL_SPIRIT, setdAmountOut__ANIMAL_SPIRIT] = React.useState("")

 React.useEffect( () => {
    setdLpReserve__ANIMAL_SPIRIT0(formatTokenBalance(18,lpReserve__ANIMAL_SPIRIT0))
  }, [lpReserve__ANIMAL_SPIRIT0])
  React.useEffect( () => {
    setdLpReserve__ANIMAL_SPIRIT1(formatTokenBalance(18,lpReserve__ANIMAL_SPIRIT1))
  }, [lpReserve__ANIMAL_SPIRIT1])
  React.useEffect( () => {
    setdAmountOut__ANIMAL_SPIRIT(formatTokenBalance(18,amountOut__ANIMAL_SPIRIT))
  }, [amountOut__ANIMAL_SPIRIT])

  const getReserves = () => {
    gdeliLP_spiritv1.methods.getReserves().call({from: window.ethereum.selectedAddress})
      .then((reserves) => {
        setLpReserve0(reserves[0])
        setLpReserve1(reserves[1])

      })
  }
  
   const getReservesGeneral = (a,b,state) => {
    return () => {


    spiritv2Router.methods.getReserves(
      a,
      b,
      false
      )
    .call({from: window.ethereum.selectedAddress})
      .then((reserves) => {
        if (state == "acre-dai") {
          setLpReserve__ACRE_DAI0(reserves[0])
          setLpReserve__ACRE_DAI1(reserves[1])
        } else if (state == "acre-animal") {
          setLpReserve__ACRE_ANIMAL0(reserves[0])
          setLpReserve__ACRE_ANIMAL1(reserves[1])
        } else if (state == "acre-ugu") {
          setLpReserve__ACRE_UGU0(reserves[0])
          setLpReserve__ACRE_UGU1(reserves[1])
        } else if (state == "acre-mgdeli") {
          setLpReserve__ACRE_MGDELI0(reserves[0])
          setLpReserve__ACRE_MGDELI1(reserves[1])
        } else if (state == "animal-liggies") {
          setLpReserve__ANIMAL_LIGGIES0(reserves[0])
          setLpReserve__ANIMAL_LIGGIES1(reserves[1])
        } else if (state == "animal-spirit") {
          setLpReserve__ANIMAL_SPIRIT0(reserves[0])
          setLpReserve__ANIMAL_SPIRIT1(reserves[1])
        } 

        

      })
    spiritv2Router.methods.getAmountOut(
      "1".concat("0".repeat(18)),
      a,
      b
      ).call({from: window.ethereum.selectedAddress})
      .then((out) => {
        if (state == "acre-dai") {
          setAmountOut__ACRE_DAI(out["amount"])
        } else if (state == "acre-animal") {
          setAmountOut__ACRE_ANIMAL(out["amount"])
        } else if (state == "acre-ugu") {
          setAmountOut__ACRE_UGU(out["amount"])
        } else if (state == "acre-mgdeli") {
          setAmountOut__ACRE_MGDELI(out["amount"])
        } else if (state == "animal-liggies") {
          setAmountOut__ANIMAL_LIGGIES(out["amount"])
        } else if (state == "animal-spirit") {
          setAmountOut__ANIMAL_SPIRIT(out["amount"])
        }

        

      })
    }
  }

  const triggerWithdraw = () => {
    setIsPending(true)
    penergConverter.withdrawAmount(
      () => {
        setIsPending(false)
        toggleInput()
        getBalance(1)
        getBalance(3)
        }    
      )
  }


  const letItRip = () => {
    for (var i =0; i < 14; i++) {
      getBalance(i)
    }
  }

  React.useEffect(
    () => {
      (props.isActive) ? letItRip() : console.log("nothing national bank")
    }, [props.isActive]
    )


  return (
    <div className={"App"}>
   
    
     <h2>Deliland National Bank</h2>
      <div className="App-core">
      <p>Located in the capital of Deliland, the forest city of Nibo, the Deliland National Bank is an iconic stop for tourists and businessfolk alike.</p>
      <p>Here, citizens can burn gDELI in exchange for mgDELI or ENERg, provide and remove ENERg from the Energy Grid, and carry out various market making activities.</p>
        <div className="App-userBalances">
          <div>
            <img src={FarmgodCore["gdeli"]["icon"]["default"]} className="token-icon" alt="gdeli" /> 
            <span>{dbalanceOfGDeli}</span>
          </div>
          <div>
            <img src={FarmgodCore["energ"]["icon"]["default"]} className="token-icon" alt="energ" /> 
            <span>{dbalanceOfENERg}</span>
          </div>
          <div>
            <img src={FarmgodCore["penerg"]["icon"]["default"]} className="token-icon" alt="penerg" /> 
            <span>{dbalanceOfpENERg}</span>
          </div>
          <div>
            <img src={FarmgodCore["mgdeli"]["icon"]["default"]} className="token-icon" alt="mgdeli" /> 
            <span>{dbalanceOfMGDeli}</span>
          </div>
        </div>

        <div className="App-converter">
          <div className="App-converter__title">
            Mint ENERg by Burning gDELI
          </div>
          <div className="App-converter__info">
            <p>ENERg can be provided to the Deliland Energy Grid.</p>
            <p>1 gDELI is burned to create 1 ENERg. Because of the transaction tax on gDELI, burning 1 gDELI only burns 0.98 gDELI and mints 1 ENERg.</p>
            <p>This process is irreversible</p>
          </div>
          <button className="btn" onClick={openInputViewAndDoStuff(true,"add")}>Mint</button>
        </div>
        <div className="App-converter">
          <div className="App-converter__title">
            Mint mgDELI by Burning gDELI
          </div>
          <div className="App-converter__info">
            <p>mgDELI is used to govern Deliland.</p>
            <p>1 gDELI is burned to create 1000 mgDELI. Because of the transaction tax on gDELI, burning 1 gDELI only burns 0.98 gDELI and mints 1000 mgDELI.</p>
            <p>This process is irreversible</p>
          </div>
          <button className="btn" onClick={openInputViewAndDoStuff(false,"add")}>Mint</button>
        </div>
        <div className="App-converter">
          <div className="App-converter__title">
            Manage your ENERg provided to the Deliland Power Grid
          </div>
          <div className="App-converter__info">
            <p>When you provide ENERg to the power grid, you recieve pENERg. 1 ENERg = 1 pENERg</p>
            <p>pENERg can be staked for rewards, held for refinery tax distributions, and withdrawn back to ENERg at any time.</p>
            
          </div>
          <button className="btn" onClick={openInputViewAndDoStuff(false,"add",true)}>Provide</button>
          <button className="btn" onClick={openInputViewAndDoStuff(false,"remove",true)}>Remove</button>
        </div>
        <div className="App-converter">
          <div className="App-converter__title">
            Your Deliland Assets
          </div>
          <div className="App-Folio">
            <div className="Portfolio-Title">Deliland Core</div>
            <div>
              <img src={FarmgodCore["gdeli"]["icon"]["default"]} className="token-icon" alt="gdeli" /> 
              <span>{dbalanceOfGDeli}</span>
            </div>
            <div>
              <img src={FarmgodCore["energ"]["icon"]["default"]} className="token-icon" alt="energ" /> 
              <span>{dbalanceOfENERg}</span>
            </div>
            <div>
              <img src={FarmgodCore["penerg"]["icon"]["default"]} className="token-icon" alt="penerg" /> 
              <span>{dbalanceOfpENERg}</span>
            </div>
            <div>
              <img src={FarmgodCore["mgdeli"]["icon"]["default"]} className="token-icon" alt="mgdeli" /> 
              <span>{dbalanceOfMGDeli}</span>
            </div>
        </div>
        <div className="App-Folio">
            <div className="Portfolio-Title">Deliland Extended Core</div>
            <div>
              <img src={FarmgodCore["acre"]["icon"]["default"]} className="token-icon" alt="gdeli" /> 
              <span>{dbalanceOfAcre}</span>
            </div>
            <div>
              <img src={FarmgodCore["ugu"]["icon"]["default"]} className="token-icon" alt="energ" /> 
              <span>{dbalanceOfUgu}</span>
            </div>
            <div>
              <img src={FarmgodCore["liggies"]["icon"]["default"]} className="token-icon" alt="penerg" /> 
              <span>{dbalanceOfLiggies}</span>
            </div>
            <div>
              <img src={FarmgodCore["animal"]["icon"]["default"]} className="token-icon" alt="mgdeli" /> 
              <span>{dbalanceOfAnimal}</span>
            </div>

        </div>
       
        

        
        </div>
        <div className="App-converter">
          <div className="App-converter__title">
            Market Making Tools
          </div>
          <p>Full kit of market making tools coming soon!</p>
          <h3>Spirit V1 gDELI-FTM LP</h3>
          <p>gDELI Reserves: {dlpReserve1}</p>
          <p>FTM Reserves: {dlpReserve0}</p>
          <button className="btn" onClick={getReserves}>Check</button>
          <h3>Spirit V2 ACRE-DAI LP</h3>
          <p>ACRE Reserves: {dlpReserve__ACRE_DAI0}</p>
          <p>DAI Reserves: {dlpReserve__ACRE_DAI1}</p>
          <p>1 ACRE = {damountOut__ACRE_DAI} DAI</p>
          <button 
            className="btn" 
            onClick={getReservesGeneral(
              FarmgodCore["acre"]["address"],
              standardTokens["dai"]["address"],"acre-dai")}>Check</button>
          <h3>Spirit V2 ACRE-ANIMAL LP</h3>
          <p>ACRE Reserves: {dlpReserve__ACRE_ANIMAL0}</p>
          <p>ANIMAL Reserves: {dlpReserve__ACRE_ANIMAL1}</p>
          <p>1 ACRE = {damountOut__ACRE_ANIMAL} ANIMAL</p>
          <button 
            className="btn" 
            onClick={getReservesGeneral(
              FarmgodCore["acre"]["address"],
              FarmgodCore["animal"]["address"],"acre-animal")}>Check</button>
          <h3>Spirit V2 ACRE-UGU LP</h3>
          <p>ACRE Reserves: {dlpReserve__ACRE_UGU0}</p>
          <p>UGU Reserves: {dlpReserve__ACRE_UGU1}</p>
          <p>1 ACRE = {damountOut__ACRE_UGU} UGU</p>
          <button 
            className="btn" 
            onClick={getReservesGeneral(
              FarmgodCore["acre"]["address"],
              FarmgodCore["ugu"]["address"],"acre-ugu")}>Check</button>
          <h3>Spirit V2 ACRE-mgDELI LP</h3>
          <p>ACRE Reserves: {dlpReserve__ACRE_MGDELI0}</p>
          <p>MGDELI Reserves: {dlpReserve__ACRE_MGDELI1}</p>
          <p>1 ACRE = {damountOut__ACRE_MGDELI} mgDELI</p>
          <button 
            className="btn" 
            onClick={getReservesGeneral(
              FarmgodCore["acre"]["address"],
              FarmgodCore["mgdeli"]["address"],"acre-mgdeli")}>Check</button>
          <h3>Spirit V2 ANIMAL-LIGGIES LP</h3>
          <p>ANIMAL Reserves: {dlpReserve__ANIMAL_LIGGIES0}</p>
          <p>LIGGIES Reserves: {dlpReserve__ANIMAL_LIGGIES1}</p>
          <p>1 ANIMAL = {damountOut__ANIMAL_LIGGIES} LIGGIES</p>
          <button 
            className="btn" 
            onClick={getReservesGeneral(
              FarmgodCore["animal"]["address"],
              FarmgodCore["liggies"]["address"],"animal-liggies")}>Check</button>
          <h3>Spirit V2 ANIMAL-SPIRIT LP</h3>
          <p>ANIMAL Reserves: {dlpReserve__ANIMAL_SPIRIT0}</p>
          <p>SPIRIT Reserves: {dlpReserve__ANIMAL_SPIRIT1}</p>
          <p>1 ANIMAL = {damountOut__ANIMAL_SPIRIT} SPIRIT</p>
          <button 
            className="btn" 
            onClick={getReservesGeneral(
              FarmgodCore["animal"]["address"],
              standardTokens["spirit"]["address"],"animal-spirit")}>Check</button>
        </div>
      </div>

      <InputView 
        title={(isTargetEnerg) ? "Minting ENERg" : "Minting mgDELI"}
        isEnerg={isTargetEnerg}
        isPenerg={isTargetPenerg}
        toggle={theInputTOGGLE}
        intent={theInputINTENT}
        theInputRef={theInputRef}
        setInputRefToMAX={setInputRefToMAX}
        poolBalance={(isTargetPenerg) ? formatTokenBalance(9,balanceOfpENERg) : formatTokenBalance(9, (isTargetEnerg) ? balanceOfENERg : balanceOfMGDeli)}
        balance={(isTargetPenerg) ? formatTokenBalance(9,balanceOfENERg) : formatTokenBalance(9,balanceOfGDeli)}
        allowance={formatTokenBalance(9, allowanceOfTarget)}
        toggleInput={toggleInput}
        onClick_Approve={onClick_Approve}
        triggerDeposit={triggerDeposit}
        triggerWithdraw={triggerWithdraw} />

      <div className={"pending pending--" + isPending}>Pending Transaction</div>


   </div>
  );
}


