// Author: Ianoda aka Maxus -- maxus.blog

import React from 'react';
import Web3 from 'web3'; 
import numeral from 'numeral';
import { BigNumber } from '@ethersproject/bignumber';

import { InputView } from './inputview.js'
import { formatTokenBalance, 
         searchAddress, 
         fromTokenNameTo, 
         fromTokenNameToDecimals, 
         fromTokenNameToAddress, 
         tokenNameAddyMap } from '../tools/tokenUtilities.js'

import { chainMap, enforceChain } from '../tools/ChainTools.js';
import { contractConfigs, FarmgodCore } from './farmgod-core.js';

// MATH STUFF
const GEN = contractConfigs["generic"]["abi"]
const REFINE = contractConfigs["refinery"]["abi"]
const INGS = ["wheat","sugarcane","gseed","yomopu"]
const materials = [
  ["flour","wholeflour"],
  ["sugar", "brownsugar"],
  ["gcoil", "gpoil"],
  ["puju", "momi"]
]

function handleChainChange(chainId) {
      window.location.reload();
    }


export const Refinery = (props) => {

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  
  function cleanInputAmount (amt, approve=false, isRetry=false) {

    let decNum = 18
    let dec = BigNumber.from(10).pow(decNum)
    let cleanInputAmt = amt.toString();
    cleanInputAmt = cleanInputAmt.replaceAll(",","")
    let adjustor = (approve) ? 1 : -1

    let regDot = /[.]/g
    let locationOfDecimal = cleanInputAmt.search(regDot)

    let cleanInput;
    if (locationOfDecimal === -1) {
      cleanInput = cleanInputAmt + "0".repeat(decNum)
    } else {
      // cocksucking decimal time
      let inputIntegerString = cleanInputAmt.substring(0, locationOfDecimal);
      let inputDecimalString = cleanInputAmt.substring(locationOfDecimal + 1);
      console.log("integer : " + inputIntegerString)
      console.log("decimal : " + inputDecimalString)
      if (decNum > 0) { // this excludes ZOO from the adjustment
        if (inputDecimalString.length === decNum) {
          // probably hit max button
          cleanInput = inputIntegerString + inputDecimalString
        } else {
          // otherwise see how many decimal places are missing
          let decsMissing = decNum - inputDecimalString.length;

          // and add them
          cleanInput = inputIntegerString + inputDecimalString + "0".repeat(decsMissing)
        }
      } else {

      }
      
    }

    cleanInput = cleanInput.replace(/^0+/,'')
    let lastDigit
    if (isRetry) {
      
      lastDigit = cleanInput.substring(cleanInput.length - 1, cleanInput.length)
      //console.log(lastDigit)
      cleanInput = cleanInput.substring(0,cleanInput.length - 1)
      //console.log(cleanInput)
      lastDigit = (Number(lastDigit) > 0 ) ? (Number(lastDigit) - 1).toString() : "0"
      cleanInput = cleanInput + lastDigit
    }

    return cleanInput;
  }
  // this is a reference to the input field
  const theInputRef = React.createRef(0);
  // this is the web3 instance used throughout the dApp
  var web3 = props.web3

  //--------CONTRACTS-------------------------------------------

  //--ingredients
  var bushel = new web3.eth.Contract(GEN, FarmgodCore["wheat"]["address"])
  var cane = new web3.eth.Contract(GEN, FarmgodCore["sugarcane"]["address"])
  var gseed = new web3.eth.Contract(GEN, FarmgodCore["gseed"]["address"])
  var yomopu = new web3.eth.Contract(GEN, FarmgodCore["yomopu"]["address"]) 
  var ingredientContract = [bushel, cane, gseed, yomopu]
  
  //--processed materials
  var flour = new web3.eth.Contract(REFINE, FarmgodCore["flour"]["address"])
  var wflour = new web3.eth.Contract(REFINE, FarmgodCore["wholeflour"]["address"])
  var sugar = new web3.eth.Contract(REFINE, FarmgodCore["sugar"]["address"])
  var bsugar = new web3.eth.Contract(REFINE, FarmgodCore["brownsugar"]["address"])
  var gcoil = new web3.eth.Contract(REFINE, FarmgodCore["gcoil"]["address"])
  var gpoil = new web3.eth.Contract(REFINE, FarmgodCore["gpoil"]["address"])
  var puju = new web3.eth.Contract(REFINE, FarmgodCore["puju"]["address"])
  var momi = new web3.eth.Contract(REFINE, FarmgodCore["momi"]["address"])

  var materialContract = [[flour, wflour], [sugar, bsugar], [gcoil, gpoil], [puju, momi]]


  //--grid
  var grid = new web3.eth.Contract(contractConfigs["thegrid"]["abi"], contractConfigs["thegrid"]["address"])




  const letItRip = () => {
    
  }

  const [step2ready, setStep2Ready] = React.useState(false)
  const [step3ready, setStep3Ready] = React.useState(false)
  const [step4ready, setStep4Ready] = React.useState(false)

  const [selectedIngredient, setSelectedIngredient] = React.useState(999);
  const selectIngredient = (n) => {
    return () => {
      setSelectedIngredient(n)
      setStep2Ready(true)
    }
  }

  const [refinesTo, setRefinesTo] = React.useState(["",""])
  const [refinesToIcons, setRefinesToIcons] = React.useState(["",""])
  const [displayIngredient, setDisplayIngredient] = React.useState("")
  const [ingredientChangeTriggered, setIngredientChangeTriggered] = React.useState(false);
  
  React.useEffect(() => {
    console.log("selectedIngredient has changed", selectedIngredient, ingredientChangeTriggered)
    if (selectedIngredient < 4 && !ingredientChangeTriggered) {
      setIngredientChangeTriggered(true)
    }
  }, [selectedIngredient])
  React.useEffect(() => {
    console.log(
      "ingredientChangeTriggered has changed", 
      ingredientChangeTriggered)
    var mats
    if (selectedIngredient < 4 && ingredientChangeTriggered) {
      mats = materials[selectedIngredient]
      setRefinesTo(mats)
      console.log("refinery mats", mats)
      console.log("refinery mats icon", FarmgodCore[mats[0]]["icon"]["default"])
      setRefinesToIcons(
        [FarmgodCore[mats[0]]["icon"]["default"],
        FarmgodCore[mats[1]]["icon"]["default"]])
      setDisplayIngredient(FarmgodCore[INGS[selectedIngredient]]["name"])
      setIngredientChangeTriggered(false)
      ingredientContract[selectedIngredient].methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
        .then((bal) => {
          setBalanceOfSelectedIngredient(bal)
          
        }).catch((err) => {
          console.log(err)
        })
    }
    
  }, [ingredientChangeTriggered])

  const [selectedMaterial, setSelectedMaterial] = React.useState([999,999])
  const [balanceOfSelectedIngredient, setBalanceOfSelectedIngredient] = React.useState(0)
  const selectMaterial = (n) => {
    return () => {
      setSelectedMaterial([selectedIngredient, n])
      setStep3Ready(true)
    }
  }

  const [displayMaterial, setDisplayMaterial] = React.useState("")
  React.useEffect(()=>{
    if (selectedMaterial[0] < 4) {
      setDisplayMaterial(FarmgodCore[materials[selectedMaterial[0]][selectedMaterial[1]]]["name"])
    }
  },[selectedMaterial])

  const [dbal, setdBal] = React.useState("")

  React.useEffect(()=>{
    setdBal(formatTokenBalance(18,balanceOfSelectedIngredient))
  },[balanceOfSelectedIngredient])

  const [relevantAllowance, setRelevantAllowance] = React.useState(0)
  const [costPerIngredient, setCostPerIngredient] = React.useState(0)
  const [exchangeRate, setExchangeRate] = React.useState(0)
  const [tax, setTax] = React.useState(0)
  const [cost, setCost] = React.useState(0)
  const calculateProcess = () => {
    if (theInputRef.current !== null && typeof theInputRef.current !== "undefined") {

    var ref = theInputRef.current
    console.log(theInputRef.current)
    ingredientContract[selectedIngredient].methods.allowance(
      window.ethereum.selectedAddress, 
      FarmgodCore[materials[selectedMaterial[0]][selectedMaterial[1]]]["address"]).call({from: window.ethereum.selectedAddress})
        .then((amt) => {
          setRelevantAllowance(amt)
          materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.costPerRawIngredient().call({from: window.ethereum.selectedAddress})
            .then((cpi) => {
              setCostPerIngredient(cpi)
              materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.exchangeRate().call({from: window.ethereum.selectedAddress})
                .then((rate) => {
                  setExchangeRate(rate)
                  materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.inputTaxBasisPoints().call({from: window.ethereum.selectedAddress})
                    .then((bp)=>{
                      setTax(bp)
                      materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.getCost(cleanInputAmount(ref.value))
                        .call({from: window.ethereum.selectedAddress})
                        .then((c)=>{
                          setCost(c)
                          setStep4Ready(true)
                        })
                    })
                })
            })
        })
      }

  }

  const [drelevantAllowance, setdRelevantAllowance] = React.useState("")
  
  React.useEffect(()=>{
    setdRelevantAllowance(formatTokenBalance(18,relevantAllowance))
  },[relevantAllowance])
  const [dcostPerIngredient, setdCostPerIngredient] = React.useState("")
   React.useEffect(()=>{
    setdCostPerIngredient(formatTokenBalance(18,costPerIngredient))
  },[costPerIngredient])
   const [dcost, setdCost] = React.useState("")
   const [doutput, setdOutput] = React.useState(0)
   React.useEffect(()=>{
    setdCost(formatTokenBalance(18,cost))
    setdOutput(calculateOutput(theInputRef.current.value,tax,exchangeRate))
  },[cost])

   const calculateOutput = (v,t,r) => {
    return (v - v*t/10000)*r 
   }

   const triggerApproval = () => {
    if (selectedIngredient < 4 && selectedMaterial[0] < 4) {
      setIsPending(true)
      ingredientContract[selectedIngredient].methods.approve(
        FarmgodCore[materials[selectedMaterial[0]][selectedMaterial[1]]]["address"], 
        cleanInputAmount(100000))
        .send({from: window.ethereum.selectedAddress})
        .then(()=>{
          setIsPending(false)
          ingredientContract[selectedIngredient].methods.allowance(
            window.ethereum.selectedAddress, 
            FarmgodCore[materials[selectedMaterial[0]][selectedMaterial[1]]]["address"]).call({from: window.ethereum.selectedAddress})
            .then((amt) => {
              setRelevantAllowance(amt)
            })
        })
    }
    
   }

  const triggerProcess = () => {
    if (selectedIngredient < 4 && selectedMaterial[0] < 4 && theInputRef.current) {
      setIsPending(true)
      materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.processRawIngredient(cleanInputAmount(theInputRef.current.value))
        .send({from: window.ethereum.selectedAddress, value: cost})
        .then(()=>{
          setIsPending(false)
          setStep2Ready(false)
          setStep3Ready(false)
          setStep4Ready(false)
        })
    }
  }


  return (
    <div className={"App" + " App--" + props.connected}>
    
    <h2>The Refinery</h2>
      
      <div className="App-preconnect">
        <h3>Connect to use The Refinery</h3>
      </div>
      <div className="App-core">
        <p>South of Lake Lauren, the Deliland Industrial Park houses most of the refineries in Deliland.</p>
        <p>Farmers, traders, and bakers gather here to refine raw ingredients into ingredients suitable for cooking.</p>
        <div className="step-1 select-ingredient">
          <h3>Step 1: Select an Ingredient to Refine</h3>
          <div className="ingredient" onClick={selectIngredient(0)}>
            <img src={FarmgodCore["wheat"]["icon"]["default"]} /> <span>BUSHEL</span></div>
          <div className="ingredient" onClick={selectIngredient(1)}>
            <img src={FarmgodCore["sugarcane"]["icon"]["default"]} /> <span>CANE</span></div>
          <div className="ingredient" onClick={selectIngredient(2)}>
          <img src={FarmgodCore["gseed"]["icon"]["default"]} /> <span>gSEED</span></div>
          <div className="ingredient" onClick={selectIngredient(3)}>
          <img src={FarmgodCore["yomopu"]["icon"]["default"]} /> <span>YOMOPU</span></div>
        </div>
        <div className={"step-2 select-material step--" + step2ready}>
          <h3>Step 2: Select an output material</h3>
          <div className="material" onClick={selectMaterial(0)}>
            <img src={refinesToIcons[0]} /> <span>{refinesTo[0]}</span></div>
          <div className="material" onClick={selectMaterial(1)}>
            <img src={refinesToIcons[1]} /> <span>{refinesTo[1]}</span></div>
        </div>
        <div className={"step-3 amount-to-process step--" + step3ready}>
          <h3>Step 3: Enter how much ingredient you wish to process.</h3>
          <p>Balance: {dbal} {displayIngredient}</p>
          <input type="text" id="theInput" defaultValue="0" ref={theInputRef} />
          <button className="btn" onClick={calculateProcess}>Proceed</button>
        </div>
        <div className={"step-4 review step--" + step4ready}>
          <h3>Step 4: Review</h3>
          <p>Allowance: {drelevantAllowance} {displayIngredient}</p>
          <p>FTM Cost per Ingredient: {dcostPerIngredient} FTM</p>
          <p>Exchange Rate: {exchangeRate} {displayMaterial} per {displayIngredient}</p>
          <p>Ingredient Tax Rate: {tax/100}%</p>
          <p>Your Total FTM Cost: {dcost}</p>
          <p>Your Output: {doutput} {displayMaterial}</p>
          <button className="btn" onClick={triggerApproval}>Approve</button>
          <button className="btn" onClick={triggerProcess}>Process</button>
        </div>

        <div className="current-process">
          <h3>Your Selections</h3>
          <div className="selection">Refining: {displayIngredient}</div>
          <div className="selection">Target: {displayMaterial}</div>
        </div>

      </div>
      
      <div className={"pending pending--" + isPending}>Pending Transaction</div>

   </div>
  );
}


