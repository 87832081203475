import { contractConfigs, poolConfigs, rewardToken, FarmgodCore } from './farmgod-core.js';
import { BigNumber } from '@ethersproject/bignumber';
import { fromAddressTo, fromTokenNameToAddress, tokenNameAddyMap, fromTokenNameToDecimals } from '../tools/tokenUtilities.js'
const CHEF = contractConfigs["providers"]["address"]
const CHEFABI = contractConfigs["providers"]["abi"]
const GEN = contractConfigs["generic"]["abi"]
const PENERG = contractConfigs["penerg"]["abi"];
const ENERG = contractConfigs["energ"]["abi"];
const PENERG_ADDY = FarmgodCore["penerg"]["address"]
const ENERG_ADDY = FarmgodCore["energ"]["address"]
// Pu aka Pool Utilities

export class Provider {
	constructor(web3, ref) {
		this.web3 = web3
		this.ref = ref
		this.dec18 = BigNumber.from(10).pow(18)
	}


	cleanInputAmount (amt, addy, approve=false, isRetry=false) {
		console.log("triggered cleaning" + tokenNameAddyMap.get(addy).name, amt)
		let decNum = 9
		let dec = BigNumber.from(10).pow(decNum)
		let cleanInputAmt = amt.toString();
		cleanInputAmt = cleanInputAmt.replaceAll(",","")
		let adjustor = (approve) ? 1 : -1

		let regDot = /[.]/g
		let locationOfDecimal = cleanInputAmt.search(regDot)

		let cleanInput;
		if (locationOfDecimal === -1) {
			cleanInput = cleanInputAmt + "0".repeat(decNum)
		} else {
			// cocksucking decimal time
			let inputIntegerString = cleanInputAmt.substring(0, locationOfDecimal);
			let inputDecimalString = cleanInputAmt.substring(locationOfDecimal + 1);
			console.log("integer : " + inputIntegerString)
			console.log("decimal : " + inputDecimalString)
			if (decNum > 0) { // this excludes ZOO from the adjustment
				if (inputDecimalString.length === decNum) {
					// probably hit max button
					cleanInput = inputIntegerString + inputDecimalString
				} else {
					// otherwise see how many decimal places are missing
					let decsMissing = decNum - inputDecimalString.length;

					// and add them
					cleanInput = inputIntegerString + inputDecimalString + "0".repeat(decsMissing)
				}
			} else {

			}
			
		}

		cleanInput = cleanInput.replace(/^0+/,'')
		let lastDigit
		if (isRetry) {
			
			lastDigit = cleanInput.substring(cleanInput.length - 1, cleanInput.length)
			//console.log(lastDigit)
			cleanInput = cleanInput.substring(0,cleanInput.length - 1)
			//console.log(cleanInput)
			lastDigit = (Number(lastDigit) > 0 ) ? (Number(lastDigit) - 1).toString() : "0"
			cleanInput = cleanInput + lastDigit
		}
		if (approve) console.log("approving " + cleanInput)
		if (!approve) console.log("depositing/withdrawing " + cleanInput)
		return cleanInput;
	}

	approveAmount (callback)  {
		console.log("triggered approval")
		const tract = new this.web3.eth.Contract(PENERG, PENERG_ADDY);
		let cleanInput = this.cleanInputAmount(this.ref.current.value, PENERG_ADDY, true)
		tract.methods.approve(CHEF, cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	checkAllowance (callback)  {
		const tract = new this.web3.eth.Contract(PENERG, PENERG_ADDY);

		console.log("Checking Allowance")

		tract.methods.allowance(window.ethereum.selectedAddress, CHEF)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}


	getBalance (addy, callback) {
		const tract = new this.web3.eth.Contract(GEN, addy);

		console.log("Getting Balance")

		tract.methods.balanceOf(window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	depositAmount (poolId, callback) {
		console.log("triggered deposit " + poolId)
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		let cleanInput = this.cleanInputAmount(this.ref.current.value, PENERG_ADDY)
		console.log(cleanInput)
		tract.methods['0xe2bbb158'](poolId, cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	harvest (poolId, callback)  {
		console.log("triggered harvest")
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		tract.methods.deposit(poolId, 0)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	withdrawAmount (poolId, callback) {
		console.log("triggered withdraw")
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		let cleanInput = this.cleanInputAmount(this.ref.current.value, PENERG_ADDY)
		console.log("withdrawAmount: ",cleanInput)
		tract.methods['0x441a3e70'](poolId, cleanInput).estimateGas({from: window.ethereum.selectedAddress},
			(error, gasAmount) => {
				if (!error) {
					tract.methods['0x441a3e70'](poolId, cleanInput)
						.send({from: window.ethereum.selectedAddress})
						.then(callback)
				} 
			})
			
	}

	triggerApproval(callback) {
	
		this.approveAmount(
			() => {
				this.checkAllowance(
					callback
				)
				
			}
		)
				
	}
	

	

	//-----------------------------------------------------------------------------------------------

	poolAmount (callback) {
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		tract.methods.poolAmount().call({from: window.ethereum.selectedAddress}).then(callback)
	}

	poolInfo(poolId, callback) {
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		tract.methods.poolInfo(poolId).call({from: window.ethereum.selectedAddress}).then(callback)

		//RewardToken address, TokenPrecision uint8, pENERgStakedAmount uint256, RewardPerSecond uint256, accRewardPerShare uint256, protocolOwnerAddress address, lastRewardTime uint32, endTime uint32, startTime uint32
	}

	userInfo(poolId, callback) {
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		tract.methods.userInfo(poolId, window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress}).then(callback)
		// amount uint256, rewardDebt uint256
	}

	pendingRewards(poolId, callback) {
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		tract.methods.pendingRewards(poolId, window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress}).then(callback)
	}
	

}

