// Author: Ianoda aka Maxus -- maxus.blog

import React from 'react';
import Web3 from 'web3'; 
import numeral from 'numeral';
import { BigNumber } from '@ethersproject/bignumber';

import { formatTokenBalance, searchAddress, fromTokenNameTo, fromTokenNameToDecimals, fromTokenNameToAddress, fromAddressTo } from '../tools/tokenUtilities.js'

import { chainMap, enforceChain } from '../tools/ChainTools.js';
import { contractConfigs, FarmgodCore, beetsPools } from './farmgod-core.js';
import { Ugu } from '../tools/Ugu.js'

// MATH STUFF
const CHEF_ABI = contractConfigs["ugufarm"]["abi"]
const CHEF_ADDY = contractConfigs["ugufarm"]["address"]
const pools = [
  "ugu",
  "bpt-acre",
  "bpt-gcore",
  "bpt-expdeli",
  "bpt-xcore",
  "bpt-uguraw",
  "bpt-grefine",
  "bpt-graw",
  "bpt-gocean",
  "bpt-xpdeli2"
]

const InputView = (props) => (

  <div className={"theInput TOGGLE__theInput--" + props.toggle + " INTENT__theInput--" + props.intent}>
        <input type="text" id="theInput" defaultValue="0" ref={props.theInputRef} />
        <button className="btn btn--max" onClick={props.setInputRefToMAX}>MAX</button>
        <div className="theInput__poolbalance">Pool Balance: <span>{props.poolBalance}</span></div>

        <div className="theInput__balance">Balance: <span>{props.balance}</span></div>
        <div className="theInput__allowance">Allowance: <span>{props.allowance}</span></div>
        <button className="btn btn--back" onClick={props.toggleInput}>back</button>
        <button className="btn btn--approve" onClick={props.onClick_Approve}>Approve This Amount</button>
        <button className="btn btn--deposit" onClick={props.triggerDeposit}>Deposit This Amount</button>
        <button className="btn btn--withdraw" onClick={props.triggerWithdraw}>Withdraw This Amount</button>
      </div>
      )

export const UguFarm = (props) => {

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

 
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  // this is a reference to the input field
  const theInputRef = React.createRef();


  const [theTargetPool, setTheTargetPool] = React.useState(0)

 

  // this is the web3 instance used throughout the dApp
  var web3 = props.web3


//  END SCRIPT

  var ugufarm = new web3.eth.Contract(CHEF_ABI, CHEF_ADDY)
  var ugu = new Ugu(web3,"ugufarm",theInputRef)
  // get the poolLength aka the number of UGU pools  
  const [poolLength, setPoolLength] = React.useState(0)

  const getPoolLength = () => {
    ugufarm.methods.poolLength().call({from: window.ethereum.selectedAddress})
      .then((res) => {
        console.log(res, "ugu pool length")
        setPoolLength(Number(res))

      })
  }

  const [uguPerBlock, setUguPerBlock] = React.useState(0)
  const getUguPerBlock = () => {
    ugufarm.methods.uguPerBlock()
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        setUguPerBlock(res)
      })
  }
  const [totalAP, setTotalAP] = React.useState(0)
  const getTotalAP = () => {
    ugufarm.methods.totalAllocPoint()
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        setTotalAP(res)
      })
  }

  // get the poolInfo for each pool
  const [poolInfo0,setPoolInfo0] = React.useState({})
  const [poolInfo1,setPoolInfo1] = React.useState({})
  const [poolInfo2,setPoolInfo2] = React.useState({})
  const [poolInfo3,setPoolInfo3] = React.useState({})
  const [poolInfo4,setPoolInfo4] = React.useState({})
  const [poolInfo5,setPoolInfo5] = React.useState({})
  const [poolInfo6,setPoolInfo6] = React.useState({})
  const [poolInfo7,setPoolInfo7] = React.useState({})
  const [poolInfo8,setPoolInfo8] = React.useState({})
  const [poolInfo9,setPoolInfo9] = React.useState({})

  const getPoolInfo = (pid) => {
    return () => {
      ugufarm.methods.poolInfo(pid).call({from: window.ethereum.selectedAddress})
        .then((res) => {
          eval("setPoolInfo" + pid.toString() + "(res)")
        })
    }
  }

  // get the userInfo for each pool
  const [userInfo0,setUserInfo0] = React.useState({})
  const [userInfo1,setUserInfo1] = React.useState({})
  const [userInfo2,setUserInfo2] = React.useState({})
  const [userInfo3,setUserInfo3] = React.useState({})
  const [userInfo4,setUserInfo4] = React.useState({})
  const [userInfo5,setUserInfo5] = React.useState({})
  const [userInfo6,setUserInfo6] = React.useState({})
  const [userInfo7,setUserInfo7] = React.useState({})
  const [userInfo8,setUserInfo8] = React.useState({})
  const [userInfo9,setUserInfo9] = React.useState({})

  const getUserInfo = (pid) => {
    return () => {
      ugufarm.methods.userInfo(pid,window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
        .then((res) => {
          eval("setUserInfo" + pid.toString() + "(res)")
        })
    }
  }

  // get the userInfo for each pool
  const [pending0,setPending0] = React.useState({})
  const [pending1,setPending1] = React.useState({})
  const [pending2,setPending2] = React.useState({})
  const [pending3,setPending3] = React.useState({})
  const [pending4,setPending4] = React.useState({})
  const [pending5,setPending5] = React.useState({})
  const [pending6,setPending6] = React.useState({})
  const [pending7,setPending7] = React.useState({})
  const [pending8,setPending8] = React.useState({})
  const [pending9,setPending9] = React.useState({})

  const getPending = (pid) => {
    return () => {
      ugufarm.methods.pendingUgu(pid,window.ethereum.selectedAddress)
        .call({from: window.ethereum.selectedAddress})
        .then((res) => {
          eval("setPending" + pid.toString() + "(res)")
        })
    }
  }
  // when the poolLength changes from 0
  // get the poolInfo for each pool
  React.useEffect(() => {
    if (poolLength > 0) {
      getUguPerBlock()
      getTotalAP()
      let f, g, h
      for (var i = 0; i < poolLength; i++) {
        f = getPoolInfo(i)
        g = getUserInfo(i)
        h = getPending(i)
        f()
        g()
        h()
      }
    }
  }, [poolLength])

  // this function checks if all the info is fetched from the chain
  const isInfoReady = () => {
    let x = true
    var poolCheck = "'0' in poolInfo"
    var userCheck = "'0' in userInfo"
    for (var i = 0; i < poolLength; i++) {
      console.log(poolCheck + i.toString())
      console.log(eval(poolCheck + i.toString()))
      console.log(poolInfo0)
      if (!eval(poolCheck + i.toString()) || !eval(userCheck + i.toString())) {
        x = false
        break
      }
    }
    console.log(x, "isInfoReady")
    return x;
  }

  // this state manages the intent of the input overlay
  // it should be set to false initially,
  // and then set to either "add" or "remove"
  const [theInputINTENT, setTheInputINTENT] = React.useState(false);

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetADDY, setTheTargetADDY] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetNAME, setTheTargetNAME] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the pool ID of the target pool
  const [theTargetPOOL, setTheTargetPOOL] = React.useState(0);

  // this state manages the display of the input overlay
  const [theInputTOGGLE, setTheInputTOGGLE] = React.useState(false);

  // this function manages the toggling of theInputTOGGLE state
  const toggleInput = () => {
    console.log(theInputTOGGLE, "toggling")
    if (theInputTOGGLE) {
      setTheInputTOGGLE(false)
    } else {
      setTheInputTOGGLE(true)
    }
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's wallet balance of the staking token
  const [balanceOfTarget, setBalanceOfTarget] = React.useState(0);
  const smartSetBalanceOfTarget = (balance) => {
    console.log(balance, "balance of target")
    setBalanceOfTarget(balance)
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's allowance (to the CHEF) of the staking token
  const [allowanceOfTarget, setAllowanceOfTarget] = React.useState(0);
  const smartSetAllowanceOfTarget = (allowance) => {
    console.log(allowance, "allowance of target")
    setAllowanceOfTarget(allowance)
  }

  const openInputViewAndDoStuff = (pid, intent) => {
  
    let theAddy = FarmgodCore[pools[pid]]["address"]
    let theName = FarmgodCore[pools[pid]]["name"]
    let thePool = pid
    return () => {

      toggleInput()
      setTheTargetADDY(theAddy)
      setTheInputINTENT(intent)
      setTheTargetNAME(theName)
      setTheTargetPOOL(thePool)
      ugu.getBalance(theAddy, (bal) => smartSetBalanceOfTarget(bal))
      ugu.checkAllowance(theAddy, (allowance) => smartSetAllowanceOfTarget(allowance))
    }

  }

  // onClick function factory that returns appropriate harvest function
  const returnHarvestFunction = (id) => {
    return () => {
      setIsPending(true)
      ugu.harvest(
        id,
        () => {
          setIsPending(false)
          //update pending rewards
          let f = getPending(id)
          f()
        })
    }     
  }

  const barAP = (ap) => {
    return Math.round(20*ap/totalAP)
  }

  const PoolDisplay = (props) => (
    <div className="ugu-pool">
      <div className="circle circle--title"></div>
      <div className="circle circle--title2"></div>
      <div className="ugu-pool__name"><img src={props.img} /><span>Deposit: {props.name} </span></div>
      
      
      
      <div className="ugu-pool__amount">
        <div className="block">
          <div className="block-border"></div>
          <div className="block-label">
            <span>Your</span>
            <span>Deposit</span>
          </div>
          <div className="block-content">
            <strong>{props.amt}</strong>
            <img src={props.img} />
            <div>
              <span className="pct-deposit">{Math.round(100*props.amt/props.total)}%</span>
              <span className="deposit-tag">of the pool</span>
            </div>
            
          </div>
          <div className="block-buttons">
            <button className="ugu-pool__btn" 
              onClick={openInputViewAndDoStuff(props.pid, "add")}>+</button>
            <button className="ugu-pool__btn" 
              onClick={openInputViewAndDoStuff(props.pid, "remove")}>-</button>
          </div>

        </div>
      </div>
      <div className="ugu-pool__pending">
        <div className="block">
        <div className="block-border"></div>
          <div className="block-label">
            <span>Your</span>
            <span>Rewards</span>
          </div>
          <div className="block-content">
            <strong>{props.pending}</strong>
            <img src={FarmgodCore["ugu"]["icon"]["default"]} />
            <div>
            <span className="pct-deposit">
              {formatTokenBalance(0,365*(props.alloc * uguPerAP * props.amt/props.total ))} 
            </span>
            <span className="deposit-tag">UGU/year</span>
            </div>
            
          </div>
          <div className="block-buttons">
            <button className="ugu-pool__btn" 
              onClick={returnHarvestFunction(props.pid)}>harvest</button>
          </div>
        </div>
      </div>

      
      
      <div className="ugu-pool__link">
        <a href={props.link} target="_blank">
          {(props.pid == 0) ? "swap" : "build"} on beets.fi</a>
      </div>
      <div className="ugu-pool__burner">
        <p>Burn Ugu to increase the allocation points of the pool.</p>
        <button className="ugu-pool__btn" 
          onClick={burnUgu(props.pid,1)}>Burn 1 Ugu</button>
        <button className="ugu-pool__btn" 
          onClick={burnUgu(props.pid,2)}>Burn 2 Ugu</button>
        <button className="ugu-pool__btn" 
          onClick={burnUgu(props.pid,4)}>Burn 4 Ugu</button>
        <button className="ugu-pool__btn" 
          onClick={burnUgu(props.pid,8)}>Burn 8 Ugu</button>
      </div>
      <div className="emissions-bar-container">
        <div className="emissions-bar">
          <div className={"emissions-bar-inner length--" + barAP(props.alloc)}></div>
        </div>
        <div className="ugu-pool__rate">
          {props.alloc * uguPerAP} UGU/day
        </div>
      </div>
      <div className="ugu-pool__alloc">
        <strong>{props.alloc}</strong> <span>AP</span>
      </div>
    </div>
    )

  const f = (str) => {
    return str.substring(0, str.length - 18)
  }

  const g = (str) => {
    if (typeof str != "string") {

    } else {
      if (str.length - 18 > 0) {
        return Number(str.substring(0, str.length - 18))
      } else {
        return Number(str.substring(0, str.length - 15))/1000;
      }
    }
    
    
  }

  const buildPoolDisplays = () => {
    let x = []
    for (var pid = 0; pid < poolLength; pid++) {
      x[pid] = <PoolDisplay 
        pid={pid}
        name={FarmgodCore[pools[pid]]["name"]}
        alloc={eval("poolInfo" + pid.toString() + "['1']")}
        amt={g(eval("userInfo" + pid.toString() + "['0']"))}
        pending={
          formatTokenBalance(
            18, 
            eval("pending" + pid.toString()))}
        link={FarmgodCore[pools[pid]]["link"]}
        total={ 
            f(eval("poolInfo" + pid.toString() + "['6']"))}
        img={FarmgodCore[pools[pid]]["icon"]["default"]} />
    }
    return x
  }

  const [builtPools, setBuiltPools] = React.useState([])
  const [pendingList, setPendingList] = React.useState([])
  React.useEffect( ()=> {
    if (isInfoReady()) {
      setBuiltPools(buildPoolDisplays)
      setPendingList(getPendingList)
    }
  }, [poolInfo0, 
      poolInfo1, 
      poolInfo2, 
      poolInfo3, 
      poolInfo4, 
      poolInfo5, 
      poolInfo6, 
      poolInfo7, 
      poolInfo8, 
      userInfo0, 
      userInfo1, 
      userInfo2, 
      userInfo3, 
      userInfo4, 
      userInfo5, 
      userInfo6, 
      userInfo7, 
      userInfo8])

  // onClick function for approving a given amount of the staking token
  const onClick_Approve = () => {
    setIsPending(true)
    ugu.triggerApproval(
      theTargetADDY, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
  }
  

  // onClick function for depositing to theTargetPOOL
  const triggerDeposit = () => {
    setIsPending(true)
    console.log("target pool: " + theTargetPOOL)
    ugu.depositAmount(
      theTargetPOOL, pools[theTargetPOOL],
      () => {
        setIsPending(false)
        toggleInput();
        let f = getUserInfo(theTargetPOOL)
        f()
      }
    )
  }

  // onClick function for withdrawing from theTargetPOOL
  const triggerWithdraw = () => {
    setIsPending(true)
    ugu.withdrawAmount(
      theTargetPOOL, pools[theTargetPOOL],
      () => {
        setIsPending(false)
        toggleInput();
        let f = getUserInfo(theTargetPOOL)
        f()
      }
    )
  }
  
 
  const setInputRefToMAX = () => {
    if (theInputINTENT == "remove") {
      theInputRef.current.value = formatTokenBalance(
        fromTokenNameToDecimals(theTargetNAME), 
        eval("userInfo" + theTargetPOOL +"['0']"), 
        false)
    } else {
      
      theInputRef.current.value = formatTokenBalance(
        fromTokenNameToDecimals(theTargetNAME), 
        balanceOfTarget, 
        false)
    }
    
  }
  
  const burnUgu = (id, amt) => {
    return () => {
      setIsPending(true)
      ugu.burnUguToIncreaseAlloc(id,amt,()=>{
        setIsPending(false)
        let f = getPoolInfo(id)
        f()
        getTotalAP()
      })
    }
  }

  const getPendingList = () => {
    var x = [], j = 0
    for (var i = 0; i < poolLength; i++) {
      if (eval("pending" + i.toString()) != 0) {
        x[j] = i
        j++ 
      }
    }
    return x
  }
  const harvestMany = (list) => {
    return () => {
      setIsPending(true)
      ugufarm.methods.harvestMany(list)
        .send({from: window.ethereum.selectedAddress})
        .then((res) => {
          setIsPending(false)
        })
    }
    
  }

  const letItRip = () => {
    getPoolLength()
  }

  React.useEffect(
    () => {
      (props.isActive) ? letItRip() : console.log("nothing ugu farm")
    }, [props.isActive]
    )

  const secsToDays = (secs) => {
    return secs*24*60*60;
  }

  const [uguPerAP, setUguPerAP] = React.useState(0)
  React.useEffect( ()=> {
    setUguPerAP(
      formatTokenBalance(0,
        secsToDays(
          Number(formatTokenBalance(18,uguPerBlock))/(totalAP *1.75))))
  }, [totalAP])


  return (
    <div className={"App"}>
    <h2>Ugu's Exchange</h2>
      
      <div className="App-core">
        <p>Citizens can earn UGU by depositing UGU and BPTs.</p>
        <p>{g(uguPerBlock)} UGU are emitted per block</p>
        <p>There are {totalAP} Allocation Points</p>
        <p>If 1 Block = 1.75 seconds, {uguPerAP} UGU is emitted per Day per Allocation Point</p>
        <button className="harvest-all" onClick={harvestMany(pendingList)}>Harvest All</button>
        {builtPools.map((x,index)=>(index == 3)? "" : x)}
        <div className={"pending pending--" + isPending}>Pending Transaction</div>
      </div>

      <InputView 
        toggle={theInputTOGGLE}
        intent={theInputINTENT}
        theInputRef={theInputRef}
        setInputRefToMAX={setInputRefToMAX}
        poolBalance={
          formatTokenBalance(
            fromTokenNameToDecimals(theTargetNAME), 
            eval("userInfo" + theTargetPOOL + "['0']"))}
        balance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget)}
        allowance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), allowanceOfTarget)}
        toggleInput={toggleInput}
        onClick_Approve={onClick_Approve}
        triggerDeposit={triggerDeposit}
        triggerWithdraw={triggerWithdraw} />
 
   </div>
  );
}


