import React from 'react'
export const DashboardSideElement = (props) => {
	const [isOn, setIsOn] = React.useState( (props.isToggly) ? false : true)

	const toggle = () => {
		if (isOn) {
			setIsOn(false)
		} else {
			setIsOn(true)
		}
	}
	return (
		<div className={"side-el side-el--" + isOn + ((props.addClass) ? " side-el--" + props.addClass : "" )}>
			{ (props.isToggly) ? <div onClick={toggle} className="side-btn">{props.toggleBtn}</div> : "" }
			<div className="side-content">{props.children}</div>
		</div>
		

		)
}