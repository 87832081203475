import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { BigNumber } from '@ethersproject/bignumber';

import { chainMap, enforceChain } from '../tools/ChainTools.js'
import { useInterval } from '../tools/UseInterval.js'

import { contractConfigs, FarmgodCore } from './farmgod-core.js'
import { Token } from '../tools/token.js'

import {formatTokenBalance} from '../tools/tokenUtilities'


export const FarmsOfTerrara = (props) => {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(false);
  var web3 = props.web3

var farms = new web3.eth.Contract(contractConfigs["farm"]["abi"], contractConfigs["farm"]["address"])

const Farm = (props) => (<div className="farm" tid={props.tid}><img src={props.img} /><div><HarvestButton fid={props.tid}/><WaterButton fid={props.tid}/></div></div>)

const letItRip = () => {
  getUserBalance()
  getAcreBalance()
  getWaterBalance()
  getTotalSupply()
}
const tokenId = React.useRef();
const [userBal, setUserBal] = React.useState(0)


const getUserBalance = () => {
  farms.methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setUserBal(res)
      console.log(res)
    })
}
const [activeIndex, setActiveIndex] = React.useState(0)
const prev = () => {
  
  if (activeIndex > 0) {
    setActiveIndex(activeIndex - 1)
  }
  
}
const next = () => {
  console.log("previous",next)
  if (activeIndex < userBal - 1) {
    setActiveIndex(activeIndex + 1)
  }
  
}

const [activeFarm, setActiveFarm] = React.useState(0);
React.useEffect(()=>{
  getFarmId()
}, [activeIndex])
const getFarmId = () => {
  if (window.ethereum.selectedAddress == null) {

  } else {
    farms.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress, activeIndex).call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setActiveFarm(res)
    })
}
  }
  

const [activeImg, setActiveImg] = React.useState("");

React.useEffect(()=>{
  getFarmImg()
  getFarmType()
},[activeFarm])
const [farmType, setFarmType] = React.useState()
const getFarmType = () => {
  console.log("getting type for: ",activeFarm)
 farms.methods.getFarmType(activeFarm).call(
        {from: window.ethereum.selectedAddress})
      .then(
        (res) => {
      
          let f = 0;
          
          if (res == "Wheat Farm") {
            f = 0
          } else if (res == "Sugarcane Plantation") {
            f = 1
          } else {
            f = 2
          }
          setFarmType(f)  
        })
  
}
const getFarmImg = () => {
  if (window.ethereum.selectedAddress == null) {

  } else {
    console.log("getting img for: ",activeFarm)
 farms.methods.tokenURI(activeFarm).call(
        {from: window.ethereum.selectedAddress})
      .then(
        (res) => {
          console.log(res)
          let x = atob(res.replace(/^data:application\/(json);base64,/, ''));
          x = JSON.parse(x)
          console.log(x)
          x = x.image
          console.log(x)
          //x = atob(x.replace(/^data:image\/svg\+xml;base64,/, ''))
          //console.log(x)
          setActiveImg(x)  
        })
  }
}


const waterFarm = (id) => {
  return () => {
    console.log("id",id)
    let fid = id
    approveIfNeeded("water",
      (res) => {
        setIsPending(true)
        setPendingText("Watering Farm")
        farms.methods.waterFarm(fid)
          .send({from: window.ethereum.selectedAddress})
          .then((res) => {
            setIsPending(false)
            getFarmImg()
          })
      }
      )
  }
}



const harvest = (id) => {
  return () => {


  
  console.log(id)
  if (farmType == 0) {
    setIsPending(true)
    setPendingText("Harvesting Bushels")
    farms.methods.harvestBushels(id)
    .send({from: window.ethereum.selectedAddress})
      .then((res) => {
        setIsPending(false)
        getFarmImg()
      })
    } else if (farmType ==1) {
      setIsPending(true)
    setPendingText("Harvesting Cane")
      farms.methods.harvestCane(id)
    .send({from: window.ethereum.selectedAddress})
      .then((res) => {
        setIsPending(false)
        getFarmImg()
      })
    } else {
      setIsPending(true)
    setPendingText("Harvesting gSeed")
      farms.methods.harvestGseed(id)
    .send({from: window.ethereum.selectedAddress})
      .then((res) => {
        setIsPending(false)
        getFarmImg()
      })
    }
  }
  
}

const HarvestButton = (props) => <button onClick={harvest(props.fid)}>Harvest</button>
const WaterButton = (props) => <button onClick={waterFarm(props.fid)}>Water Farm</button>

const approveIfNeeded = (tokenName, cb = () => {}) => {
  var user = window.ethereum.selectedAddress
  var token = FarmgodCore[tokenName]["address"]
  var spender = contractConfigs["farm"]["address"]

  var tokenContract = new web3.eth.Contract(contractConfigs["generic"]["abi"], token)

  tokenContract.methods.allowance(user, spender)
    .call({from: user})
    .then((res) => {
      
      console.log(res)
      console.log(typeof res.toString())
      if (res.toString().length >= 20) {
        cb()
      } else {
        setIsPending(true)
        setPendingText("Approving Farm Contract to Spend 100,000 "+tokenName)
        tokenContract.methods.approve(spender, "100000"+ "0".repeat(18))
          .send({from: user})
          .then(cb)
      }
      
    }).catch((err) => {
        setIsPending(false)
      }) 

}

const approveWater = () => {
  var user = window.ethereum.selectedAddress
  var token = FarmgodCore["water"]["address"]
  var spender = contractConfigs["farm"]["address"]
  var tokenContract = new web3.eth.Contract(contractConfigs["generic"]["abi"], token)
  setIsPending(true)
  setPendingText("Approving Farm Contract to Spend 10,000 gH20")
  tokenContract.methods.approve(spender, "10000"+ "0".repeat(18))
    .send({from: user})
      .then((res) => {
        setIsPending(false)

      }).catch((err) => {
        setIsPending(false)
      }) 
}

const approveThenMintWheatFarm = () => {
  approveIfNeeded("acre",
    (res) => {
      approveIfNeeded("water",
        (res) => {
          setIsPending(true)
          setPendingText("Minting a Wheat Farm")
          farms.methods.createWheatFarm()
            .send({from: window.ethereum.selectedAddress})
            .then((res) => {
              getUserBalance()
              getAcreBalance()
              getWaterBalance()
              getAllGlobalEmissions()
              setViewFarms(false)
              setTimeout(()=>{setViewFarms(true)}, 400)
              setIsPending(false)
            }
              
              ).catch((err) => {
        setIsPending(false)
      }) 
        }
        )
    }
    )
}

const approveThenMintPlantation = () => {
  approveIfNeeded("acre",
    (res) => {
      approveIfNeeded("water",
        (res) => {
          setIsPending(true)
          setPendingText("Minting a Plantation")
          farms.methods.createPlantation()
            .send({from: window.ethereum.selectedAddress})
            .then((res) => {
              getUserBalance()
              getAcreBalance()
              getWaterBalance()
              getAllGlobalEmissions()
              setViewFarms(false)
              setTimeout(()=>{setViewFarms(true)}, 400)
              setIsPending(false)
            }
              
              ).catch((err) => {
        setIsPending(false)
      }) 
        }
        )
    }
    )
}

const approveThenMintGseedFarm = () => {
  approveIfNeeded("acre",
    (res) => {
      approveIfNeeded("water",
        (res) => {
          setIsPending(true)
          setPendingText("Minting a gSeed Farm")
          farms.methods.createGseedFarm()
            .send({from: window.ethereum.selectedAddress})
            .then((res) => {
              getUserBalance()
              getAcreBalance()
              getWaterBalance()
              getAllGlobalEmissions()
              setViewFarms(false)
              setTimeout(()=>{setViewFarms(true)}, 400)
              setIsPending(false)
            }
              
              ).catch((err) => {
        setIsPending(false)
      }) 
        }
        )
    }
    )
}

const [acreBal, setAcreBal] = React.useState(0)
const getAcreBalance = () => {
  let x = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["acre"]["address"])
  x.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setAcreBal(res)
    })
}

const [waterBal, setWaterBal] = React.useState(0)
const getWaterBalance = () => {
  let x = new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore["water"]["address"])
  x.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setWaterBal(res)
    })
}



const [viewFarms, setViewFarms] = React.useState(false)


const [pendingText, setPendingText] = React.useState("")
const [showMinters, setShowMinters] = React.useState(false)


const [bushelEmissions, setBushelEmissions] = React.useState(0)
const [caneEmissions, setCaneEmissions] = React.useState(0)
const [gseedEmissions, setGseedEmissions] = React.useState(0)

const getAllGlobalEmissions = () => {
  farms.methods.getBushelEmissions()
    .call({from: window.ethereum.selectedAddress})
    .then(
      (res) => {
        setBushelEmissions(res)

      })
    farms.methods.getCaneEmissions()
    .call({from: window.ethereum.selectedAddress})
    .then(
      (res) => {
        setCaneEmissions(res)
        
      })
    farms.methods.getGseedEmissions()
    .call({from: window.ethereum.selectedAddress})
    .then(
      (res) => {
        setGseedEmissions(res)
        
      })
}
const [showWallet, setShowWallet] = React.useState(false);
const [showEmissions, setShowEmissions] = React.useState(false);
const [showBurnStats, setShowBurnStats] = React.useState(false);
const [totalSupply, setTotalSupply] = React.useState(false);
const getTotalSupply = () => {
  farms.methods.totalSupply()
    .call({from: window.ethereum.selectedAddress})
    .then(
      (res) => {
        setTotalSupply(res)
      })
}

const refreshFarms = () => {
  getFarmId()
}

React.useEffect(
    () => {
      (props.isActive) ? letItRip() : console.log("nothing farms terrara")
    }, [props.isActive]
    )
  return (
    <div className={"App-wrapper " + " connected--" + props.connected}>
      
      <div>  
          <div className={"farms farms--" + viewFarms}>
            <Farm img={activeImg} tid={activeFarm}/>
            <div className="farmnav">
            <button className="btn" onClick={prev}>Previous</button>
            <span>{activeIndex}</span>
            <button className="btn" onClick={next}>Next</button>
            </div>
          </div>    
      </div>
      <div className={"mint-btns mint-btns--" + showMinters}>
          <span>Cost: 100 Acres & 50 Water</span>
          <button 
            onClick={() => {
              approveThenMintGseedFarm() }}>
              Mint New Gseed Farm</button>
          <button 
            onClick={() => {
              approveThenMintWheatFarm() }}>
              Mint New Wheat Farm</button>
          <button 
            onClick={() => {
              approveThenMintPlantation() }}>
              Mint New Plantation</button>
      </div>
      
      <div className="header"><h2>Farms of Terrara: You have {userBal} Farms</h2></div>
      <div className={"pender isPending--" + isPending}>{pendingText}</div>
      
       <div className="control-btns">
          <button  onClick={() => {
          (viewFarms) ? setViewFarms(false) : setViewFarms(true) }}>View Farms</button>
          </div>
          
          <button className="toggle-minters" onClick={()=>{(showMinters) ? setShowMinters(false) : setShowMinters(true)}}>Toggle Minting Panel</button>
          <button className="approve-water" onClick={approveWater}>Approve 10k gH20</button>
          <button className="refresh-farms" onClick={refreshFarms}>Refresh Farms</button>
      <p>Vast and fertile stretches of land outside the city of Terrara are used by many Deliland citizens to grow bushels of wheat, sugarcane, and oil bearing seeds.</p>
     
    </div>

  );
}

