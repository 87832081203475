import { contractConfigs, FarmgodCore } from './farmgod-core.js';
import { BigNumber } from '@ethersproject/bignumber';
import { fromAddressTo, fromTokenNameToAddress, tokenNameAddyMap, fromTokenNameToDecimals } from '../tools/tokenUtilities.js';
const PENERG = contractConfigs["penerg"]["abi"];
const ENERG = contractConfigs["energ"]["abi"];
const PENERG_ADDY = FarmgodCore["penerg"]["address"]
const ENERG_ADDY = FarmgodCore["energ"]["address"]


export class PenergConverter {
	constructor(web3, ref) {
		this.web3 = web3
		this.ref = ref
		this.dec9 = BigNumber.from(10).pow(9)
	}


	cleanInputAmount (isEnerg, amt, approve=false, isRetry=false) {
		let ADDY = (isEnerg) ? ENERG_ADDY : PENERG_ADDY
		console.log("triggered cleaning" + tokenNameAddyMap.get(ADDY).name, amt)
		let decNum = fromTokenNameToDecimals(tokenNameAddyMap.get(ADDY).name)
		let dec = BigNumber.from(10).pow(decNum)
		let cleanInputAmt = amt.toString();
		cleanInputAmt = cleanInputAmt.replaceAll(",","")
		let adjustor = (approve) ? 1 : -1

		let regDot = /[.]/g
		let locationOfDecimal = cleanInputAmt.search(regDot)

		let cleanInput;
		if (locationOfDecimal === -1) {
			cleanInput = cleanInputAmt + "0".repeat(decNum)
		} else {
			// cocksucking decimal time
			let inputIntegerString = cleanInputAmt.substring(0, locationOfDecimal);
			let inputDecimalString = cleanInputAmt.substring(locationOfDecimal + 1);
			console.log("integer : " + inputIntegerString)
			console.log("decimal : " + inputDecimalString)
			if (decNum > 0) { // this excludes ZOO from the adjustment
				if (inputDecimalString.length === decNum) {
					// probably hit max button
					cleanInput = inputIntegerString + inputDecimalString
				} else {
					// otherwise see how many decimal places are missing
					let decsMissing = decNum - inputDecimalString.length;

					// and add them
					cleanInput = inputIntegerString + inputDecimalString + "0".repeat(decsMissing)
				}
			} else {

			}
			
		}

		cleanInput = cleanInput.replace(/^0+/,'')
		let lastDigit
		if (isRetry) {
			
			lastDigit = cleanInput.substring(cleanInput.length - 1, cleanInput.length)
			//console.log(lastDigit)
			cleanInput = cleanInput.substring(0,cleanInput.length - 1)
			//console.log(cleanInput)
			lastDigit = (Number(lastDigit) > 0 ) ? (Number(lastDigit) - 1).toString() : "0"
			cleanInput = cleanInput + lastDigit
		}
		if (approve) console.log("approving " + cleanInput)
		if (!approve) console.log("depositing/withdrawing " + cleanInput)
		return cleanInput;
	}

	approveSpend (isEnerg, callback)  {
		console.log("triggered approval")
		let toApprove = (isEnerg) ? ENERG : PENERG
		let toApprove_addy = (isEnerg) ? ENERG_ADDY : PENERG_ADDY
		const tract = new this.web3.eth.Contract(toApprove, toApprove_addy);
		
		let cleanInput = this.cleanInputAmount(isEnerg, this.ref.current.value, true)
		tract.methods.approve(PENERG_ADDY, cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	checkAllowance (isEnerg, callback)  {
	
		let toApprove = (isEnerg) ? ENERG : PENERG
		let toApprove_addy = (isEnerg) ? ENERG_ADDY : PENERG_ADDY
		const tract = new this.web3.eth.Contract(toApprove, toApprove_addy);
		

		console.log("Checking Allowance")

		tract.methods.allowance(window.ethereum.selectedAddress, PENERG_ADDY)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}


	getBalance (isEnerg, callback) {
		let a = (isEnerg) ? ENERG : PENERG
		let a_addy = (isEnerg) ? ENERG_ADDY : PENERG_ADDY
		const tract = new this.web3.eth.Contract(a, a_addy);

		console.log("Getting Balance")

		tract.methods.balanceOf(window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	depositAmount (callback) {
		console.log("triggered deposit ")
		
		const tract = new this.web3.eth.Contract(PENERG, PENERG_ADDY)
		let cleanInput = this.cleanInputAmount(true, this.ref.current.value)
		tract.methods.depositEnerG(cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)
		
	}

	withdrawAmount (callback) {
		console.log("triggered withdraw")
		const tract = new this.web3.eth.Contract(PENERG, PENERG_ADDY);

		
		let cleanInput = this.cleanInputAmount(false, this.ref.current.value)
		console.log("withdrawAmount: ",cleanInput)
		tract.methods.withdrawEnerG(cleanInput).estimateGas({from: window.ethereum.selectedAddress},
			(error, gasAmount) => {
				
				if (!error) {
					tract.methods.withdrawEnerG(cleanInput)
						.send({from: window.ethereum.selectedAddress})
						.then(callback)
				}
			})
			
	}
	

	triggerApproval(isEnerg, callback) {
		this.approveSpend(
			isEnerg, 
			() => {
				this.checkAllowance(
					isEnerg, 
					callback
				)
				
			}
		)
				
	}

}

