import { contractConfigs, rewardToken, FarmgodCore } from '../deliJS/farmgod-core.js';
import { BigNumber } from '@ethersproject/bignumber';
import { fromAddressTo, fromTokenNameToAddress, tokenNameAddyMap, fromTokenNameToDecimals } from './tokenUtilities.js'

const GEN = contractConfigs["generic"]["abi"]

// Pu aka Pool Utilities

export class Ugu {
	constructor(web3, chef, ref = null) {
		this.web3 = web3
		this.ref = ref
		this.dec18 = BigNumber.from(10).pow(18)
		this.CHEF = contractConfigs[chef]["address"]
		this.CHEFABI = contractConfigs[chef]["abi"]
	}


	cleanInputAmount (amt, addy) {
		console.log("triggered cleaning" + tokenNameAddyMap.get(addy).name)
		let decNum = fromTokenNameToDecimals(tokenNameAddyMap.get(addy).name)
		console.log(decNum)
		let dec = BigNumber.from(10).pow(decNum)
		let cleanInputAmt = amt.toString();
		
		
		cleanInputAmt = cleanInputAmt.replaceAll(",","")
		cleanInputAmt = Number(cleanInputAmt)
		console.log(cleanInputAmt)
		let inputDecimals = cleanInputAmt % 1;
		let inputNumber = Math.floor(cleanInputAmt - inputDecimals);
		inputDecimals = inputDecimals.toFixed(decNum);
		let inputDecimalsReady = inputDecimals * dec;
		let cleanInput = BigNumber.from(inputNumber);
		console.log(inputDecimalsReady)
		console.log(cleanInput)
		cleanInput = cleanInput.mul(dec).add(inputDecimalsReady.toString());
		return cleanInput;
	}

	approveAmount (addy, callback)  {
		console.log("triggered approval")
		const tract = new this.web3.eth.Contract(GEN, addy);
		let cleanInput = this.cleanInputAmount(this.ref.current.value, addy)
		tract.methods.approve(this.CHEF, cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	checkAllowance (addy, callback)  {
		const tract = new this.web3.eth.Contract(GEN, addy);

		console.log("Checking Allowance")

		tract.methods.allowance(window.ethereum.selectedAddress, this.CHEF)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	burnUguToIncreaseAlloc(pid, burnAmount, callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);

		console.log("burning ugu to increase Alloc points")

		tract.methods.burnUGUtoIncreaseAllocPoint(pid, burnAmount)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)
	}


	getBalance (addy, callback) {
		const tract = new this.web3.eth.Contract(GEN, addy);

		console.log("Getting Balance")

		tract.methods.balanceOf(window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	depositAmount (poolId, depositName, callback) {
		console.log("triggered deposit " + poolId)
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		let cleanInput = this.cleanInputAmount(
			this.ref.current.value, 
			FarmgodCore[depositName]["address"])
		tract.methods.deposit(poolId, cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	harvest (poolId, callback)  {
		console.log("triggered harvest")
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		tract.methods.deposit(poolId, 0)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	withdrawAmount (poolId, depositName, callback) {
		console.log("triggered withdraw")
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		let cleanInput = this.cleanInputAmount(this.ref.current.value, 
			FarmgodCore[depositName]["address"])
		tract.methods.withdraw(poolId, cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)
	}

	triggerApproval(addy, callback) {
	
		this.approveAmount(
			addy, 
			() => {
				this.checkAllowance(
					addy, 
					callback
				)
				
			}
		)
				
	}

	getPoolBalance (poolId, callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);

		return tract.methods.userInfo(poolId, window.ethereum.selectedAddress)
			.call.request({from: window.ethereum.selectedAddress}, callback)
				
	}

	getPoolInfo(poolId, callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);

		tract.methods.poolInfo(poolId, window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)
	}

	getRate(callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		//tract.methods.rewardtokenPerBlock()
		tract.methods.acrePerBlock()
			.call({from: window.ethereum.selectedAddress})
					.then(callback)
	}
	getTotalAlloc(callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		tract.methods.totalAllocPoint()
			.call({from: window.ethereum.selectedAddress})
					.then(callback)
	}

	getPendingRewards (poolId, callback, isRequest) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		if (!isRequest) {
			//tract.methods.pendingRewards(poolId, window.ethereum.selectedAddress)
			tract.methods.pendingAcre(poolId, window.ethereum.selectedAddress)
				.call({from: window.ethereum.selectedAddress})
					.then(callback)	
		} else {
			//return tract.methods.pendingRewards(poolId, window.ethereum.selectedAddress)
			return tract.methods.pendingAcre(poolId, window.ethereum.selectedAddress)
						.call.request({from: window.ethereum.selectedAddress}, callback)	
		}
		
	}

	


	totalSupply (callback) {
		const tract = new this.web3.eth.Contract(contractConfigs["generic"]["abi"], rewardToken["address"])

		tract.methods.totalSupply().call({from: window.ethereum.selectedAddress})
			.then(callback)
	}
	rewardBalance (callback) {
		const tract = new this.web3.eth.Contract(contractConfigs["generic"]["abi"], rewardToken["address"])

		tract.methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
			.then(callback)
	}

}

