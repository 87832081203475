import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { BigNumber } from '@ethersproject/bignumber';

import { chainMap, enforceChain, enforceChains } from './tools/ChainTools.js'
import { useInterval } from './tools/UseInterval.js'


import { Token } from './tools/token.js'

import {formatTokenBalance} from './tools/tokenUtilities'


import { Wallet } from './deliJS/wallet.js'

 import {Footer } from './deliJS/footer.js'

import { Store } from './deliJS/store.js'
import { Inventory } from './deliJS/inventory.js'
import { FarmgodCore, 
       basicItems, standardTokens, 
       contractConfigs, testItems, testTokens, liveCitizens } from './deliJS/farmgod-core.js'

import { SaltMine } from './deliJS/SaltApp.js'
import { Lake } from './deliJS/WaterApp.js'
import { NationalBank } from './deliJS/NationalBank.js'
import { EnergyGrid } from './deliJS/EnergyGrid.js'
import { Refinery } from './deliJS/Refinery.js'
import { FarmsOfTerrara } from './deliJS/farms-terrara.js'
import { LocationPage } from './deliJS/LocationPage.js'
import { UguFarm } from './deliJS/UguFarm.js'

import delimap from './gdeli/map-full.png'
import saltmap from './gdeli/saltmines.png'
import lakemap from './gdeli/lake.png'

export function Dapp() {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  const [activeChainId, setActiveChainId] = React.useState("")
  function handleChainChange(chainId) {
    console.log("handling chain change")
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));

    enforceChains(["Fantom","FantomTEST"], letItRip)
    setActiveChainId(chainId)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);



  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');


const letItRip = () => {

}   

  const [items, setItems] = React.useState({})
  const [stablecoin, setStablecoin] = React.useState("")
  const [itemKeys, setItemKeys] = React.useState([])
  
  React.useEffect(()=>{
    if (connected) {
      if (activeChainId == "0xfa2") { 
      
     
    } else {
      
      getCitizenBalance()
    }
    }
    
  }, [activeChainId])

  

  const [isMapOpen, setIsMapOpen] = React.useState(false)

  const [isInventoryOpen, setIsInventoryOpen] = React.useState(false)
  const [hasInventoryOpened, setHasInventoryOpened] = React.useState(false)
  const toggleInventory = () => {
    
    if (!hasInventoryOpened && !isInventoryOpen) {
     setHasInventoryOpened(true) 
    }
    if (isInventoryOpen) {
      setIsInventoryOpen(false)
    } else {
      turnOffOthers("inv")
      setIsInventoryOpen(true)
    }

    
  }

  React.useEffect(()=>{
    if (isInventoryOpen) {
      setItems(basicItems)
      setStablecoin(standardTokens["dai"]["address"])
      setItemKeys(Object.keys(basicItems))
      getCitizenBalance()
    }
  },[isInventoryOpen])

  const [isWalletOpen, setIsWalletOpen] = React.useState(false)
  const toggleWallet = () => {
    (isWalletOpen) ? setIsWalletOpen(false) : setIsWalletOpen(true)
    turnOffOthers("wallet")
  }

  const [isStoreOpen, setIsStoreOpen] = React.useState(false)
  const toggleStore = () => {
    (isStoreOpen) ? setIsStoreOpen(false) : setIsStoreOpen(true)
    turnOffOthers("store")
  }

  const [isTravelOpen, setIsTravelOpen] = React.useState(false)
  const toggleTravel = () => {
    (isTravelOpen) ? setIsTravelOpen(false) : setIsTravelOpen(true)
    turnOffOthers("travel")
  }

  
  const [activeMapSrc, setActiveMapSrc] = React.useState(delimap)
  const [activeMapTitle, setActiveMapTitle] = React.useState("Deliland")
  const toggleMap = () => {
    (isMapOpen) ? setIsMapOpen(false) : setIsMapOpen(true)
    turnOffOthers("map")
  }

  const turnOffOthers = (active) => {
    if (active == "inv") {
      setIsStoreOpen(false)
      setIsWalletOpen(false)
      setIsTravelOpen(false)
      setIsMapOpen(false)
    } else if (active == "wallet") {
      setIsInventoryOpen(false)
      setIsStoreOpen(false)
      setIsTravelOpen(false)
      setIsMapOpen(false)
    } else if (active == "store") {
      setIsInventoryOpen(false)
      setIsWalletOpen(false)
      setIsTravelOpen(false)
      setIsMapOpen(false)
    } else if (active == "travel") {
      setIsInventoryOpen(false)
      setIsStoreOpen(false)
      setIsWalletOpen(false)
      setIsMapOpen(false)
    } else {
      setIsInventoryOpen(false)
      setIsStoreOpen(false)
      setIsWalletOpen(false)
      setIsTravelOpen(false)
    }
  }

  React.useEffect(()=> {
    if (isMapOpen) {
      if (locale == "") {
        setActiveMapSrc(delimap)
        setActiveMapTitle("Deliland")
      } else if (locale == "salt") {
        setActiveMapSrc(saltmap)
        setActiveMapTitle("The Shio Region")
      } else if (locale == "lake") {
        setActiveMapSrc(lakemap)
        setActiveMapTitle("The City of Lauren")
      }
    }
  }, [isMapOpen])

  const [locale, setLocale] = React.useState("")

  const travel = (loc) => {
    return () => {
      setLocale(loc)
      setIsTravelOpen(false)
    }
  }

  const [isActive__Salt, setIsActive__Salt] = React.useState(false)
  const [isActive__Lake, setIsActive__Lake] = React.useState(false)
  const [isActive__Bank, setIsActive__Bank] = React.useState(false)
  const [isActive__Grid, setIsActive__Grid] = React.useState(false)
  const [isActive__Refinery, setIsActive__Refinery] = React.useState(false)
  const [isActive__Farms, setIsActive__Farms] = React.useState(false)
  const [isActive__UguFarms, setIsActive__UguFarms] = React.useState(false)
  const [isActive__City_Shio, setIsActive__City_Shio] = React.useState(false)
  const [isActive__City_Toray, setIsActive__City_Toray] = React.useState(false)
  const [isActive__City_Lauren, setIsActive__City_Lauren] = React.useState(false)
  const [isActive__City_Terrara, setIsActive__City_Terrara] = React.useState(false)
  const [isActive__City_Nibo, setIsActive__City_Nibo] = React.useState(false)
  const [isActive__City_Arbo, setIsActive__City_Arbo] = React.useState(false)
  React.useEffect(()=>{
    (locale == "salt") ? setIsActive__Salt(true) : setIsActive__Salt(false);
    (locale == "lake") ? setIsActive__Lake(true) : setIsActive__Lake(false);
    (locale == "bank") ? setIsActive__Bank(true) : setIsActive__Bank(false);
    (locale == "grid") ? setIsActive__Grid(true) : setIsActive__Grid(false);
    (locale == "refine") ? setIsActive__Refinery(true) : setIsActive__Refinery(false);
    (locale == "farms") ? setIsActive__Farms(true) : setIsActive__Farms(false);
    (locale == "shio") ? setIsActive__City_Shio(true) : setIsActive__City_Shio(false);
    (locale == "toray") ? setIsActive__City_Toray(true) : setIsActive__City_Toray(false);
    (locale == "lauren") ? setIsActive__City_Lauren(true) : setIsActive__City_Lauren(false);
    (locale == "terrara") ? setIsActive__City_Terrara(true) : setIsActive__City_Terrara(false);
    (locale == "nibo") ? setIsActive__City_Nibo(true) : setIsActive__City_Nibo(false);
    (locale == "arbo") ? setIsActive__City_Arbo(true) : setIsActive__City_Arbo(false);
    (locale == "ugu") ? setIsActive__UguFarms(true) : setIsActive__UguFarms(false);
  }, [locale])

  const [userBal, setUserBal] = React.useState(0)
  const getCitizenBalance = () => {
    var citizen
    if (window.ethereum.selectedAddress == null) {

    } else {
      citizen = new web3.eth.Contract(liveCitizens["citizen"]["abi"], liveCitizens["citizen"]["address"]);
      citizen.methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
        .then((res)=> {
          setUserBal(res)
          console.log(res, "user Balance")
        })
    }
  }

  const [activeCitizen, setActiveCitizen] = React.useState(999999)
  const getCitizenId = () => {
    var citizen;
    if (window.ethereum.selectedAddress == null) {

    } else {
      citizen = new web3.eth.Contract(liveCitizens["citizen"]["abi"], liveCitizens["citizen"]["address"]);
      citizen.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress, 0).call({from: window.ethereum.selectedAddress})
      .then((res) => {
        setActiveCitizen(res)
        console.log(res, "activeCitizen")
      }).catch((err)=>{
        
      })
    }
  }
  React.useEffect(()=>{
    if (userBal > 0) {
      getCitizenId()
    }
    
  },[userBal])

  const [isCitizen, setIsCitizen] = React.useState(false)
  const getIsCitizen = () => {
    var citizenship = new web3.eth.Contract(
        liveCitizens["citizenship"]["abi"], 
        liveCitizens["citizenship"]["address"]);
      citizenship.methods.getLastClaim(
        activeCitizen
        ).call({from: window.ethereum.selectedAddress})
    .then((res) => {
      console.log(res, "in getIsCitizen")
      if ( res == 0) {
        setIsCitizen(false)
      } else {
        setIsCitizen(true)
      }
      
    })
  }
  const [citizenName, setCitizenName] = React.useState("")
  const [citizenTitle, setCitizenTitle] = React.useState("")
  const getTitle = () => {
    var citizens = new web3.eth.Contract(
        liveCitizens["citizen"]["abi"], 
        liveCitizens["citizen"]["address"]);
      citizens.methods.getTitle(
        activeCitizen
        ).call({from: window.ethereum.selectedAddress})
    .then((res) => {
      console.log(res, "in getTitle")
      setCitizenTitle(res)
      })
  }
  const getName = () => {
    var citizens = new web3.eth.Contract(
        liveCitizens["citizen"]["abi"], 
        liveCitizens["citizen"]["address"]);
      citizens.methods.getName(
        activeCitizen
        ).call({from: window.ethereum.selectedAddress})
    .then((res) => {
      console.log(res, "in getName")
      setCitizenName(res)
      })
  }

  React.useEffect(()=>{
    if (activeCitizen != 999999) {
      getIsCitizen()
      getTitle()
      getName()
    }
  },[activeCitizen])

  React.useEffect(()=>{
    if (citizenTitle != "" && citizenName != "") {
       setMMBtnText(citizenTitle + " " + citizenName)
    }
   
  }, [citizenTitle,citizenName])

  const [citizenLocation, setCitizenLocation] = React.useState([4,0])

  const getCitizenLocation = () => {
    var citizenship = new web3.eth.Contract(
        liveCitizens["citizenship"]["abi"], 
        liveCitizens["citizenship"]["address"]);
    
    citizenship.methods.getLocIndex(
        activeCitizen
        ).call({from: window.ethereum.selectedAddress})
    .then((res) => {
      console.log(res, "in getCitizenLocation")
      setCitizenLocation(res)
      
    })
  }



  React.useEffect(()=>{
    if (isCitizen) {
      getCitizenLocation()
    }
  },[isCitizen])



  const [shortcutsOn, setShortcutsOn] = React.useState(false)

  const toggleShortcuts = () => {
    (shortcutsOn) ? setShortcutsOn(false) : setShortcutsOn(true)
  }
  return (
    <div className={"App-wrapper " + " connected--" + connected}>

   
      <button disabled={isDisabled} onClick={connectMM} className="mmbtn">
        {mmBtnText}
      </button>
      
      <div className={"store-container store-container--" + isStoreOpen}>  
      <Store 
        chainId={activeChainId} 
        connected={connected} 
        web3={web3} 
        items={items} 
        itemKeys={itemKeys}
        stablecoin={stablecoin}
        isActive={isStoreOpen}/>
      </div>
      
      <div className={"inventory-container inventory-container--" + isInventoryOpen}>
      <h2>Item Inventory</h2>
      {itemKeys.map((abbr)=>
        <Inventory 
          chainId={activeChainId} 
          connected={connected} 
          web3={web3} 
          abbr={abbr}
          items={items} 
          itemKeys={itemKeys}
          isActive={isInventoryOpen} />
        )}
      </div>
        
      <div className={"wallet-container wallet-container--" + isWalletOpen}>  
        {
        <Wallet web3={web3} connected={connected} isActive={isWalletOpen}/>
        }
      </div>
    <div className={"map-container map-container--" + isMapOpen}>  
        <div className="the-map">
          <img src={activeMapSrc} />
          <h3 className="the-map__title">Map of {activeMapTitle}</h3>
        </div>
      </div>


      <div className={"shortcuts shortcuts--" + connected + " shortcuts-on--" + shortcutsOn}>
        <button onClick={toggleInventory} className="toggle-btn">
          Inventory
        </button> 
        <button onClick={toggleWallet} className="toggle-btn">
          Wallet
        </button>
        <button onClick={toggleStore} className="toggle-btn">
          Store
        </button>
        <button onClick={toggleTravel} className="toggle-btn">
          Travel
        </button>
        <button onClick={toggleMap} className="toggle-btn">
          Map
        </button>
        <button onClick={toggleShortcuts} className="toggle-shortcuts">
         Toggle Nav
        </button>
      </div>

      <div className={"mint-citizen mint-citizen--" + isCitizen}>
        <h3>Welcome to Deliland</h3>
        <p>If you have a citizen, wait for this message to disappear before proceeding.</p>
        <a href="https://citizens.deliland.io" target="_blank">Otherwise, mint a Citizen to Begin</a>
      </div>

      <div className={"welcome-citizen welcome-citizen--" + isCitizen + " welcome-citizen--" + locale}>
        <h3>Welcome back Citizen!</h3>
        <p>Looking for a place to start? Start mining Salt in the Shio Region, or travel to Lake Lauren and place your rain collector.</p>
        <p>Mint a raw ingredient Farm NFT at the Farms of Terrara to start earning BUSHEL, CANE, or gSEED.</p>
        <p>Refine raw ingredients in the Industrial Zone.</p>
        <p>Provide liquidity and then farm UGU at Ugu's Exchange.</p>
        <p>Burn gDELI for ENERg and mgDELI at the Deliland National Bank. Withdraw and Deposit your pENERg and ENERg.</p>
        <p>Stake your pENERg at the Energy Grid to earn various tokens.</p>
        <p>Travel to your Citizen's registered city to claim their adjusted universal basic income in UGU.</p>
        <p>While you're there, utilize any city-specific services!</p> 
        <p>Planning ahead? Purchase items for baking at the Store and view them in your Inventory.</p>
      </div>

      

      <div className={"salt-mines salt-mines--" + locale}>
        <SaltMine web3={web3} connected={connected} isActive={isActive__Salt} />
      </div>
      <div className={"lake-lauren lake-lauren--" + locale}>
        <Lake web3={web3} connected={connected} isActive={isActive__Lake} />
      </div>

      <div className={"bank bank--" + locale}>
        <NationalBank web3={web3} connected={connected} isActive={isActive__Bank} />
      </div>

      <div className={"energy-grid energy-grid--" + locale}>
        <EnergyGrid web3={web3} connected={connected} isActive={isActive__Grid} />
      </div>

      <div className={"refinery refinery--" + locale}>
        <Refinery web3={web3} connected={connected} isActive={isActive__Refinery} />
      </div>

      <div className={"farms-container farms-container--" + locale}>
        <FarmsOfTerrara web3={web3} connected={connected} isActive={isActive__Farms} />
      </div>

      <div className={"ugu-container ugu-container--" + locale}>
        <UguFarm web3={web3} connected={connected} isActive={isActive__UguFarms} />
      </div>


      <div className={"city city-lauren--" + locale}>
        <LocationPage 
          web3={web3} 
          connected={connected} 
          isActive={isActive__City_Lauren}
          title="City of Lauren" 
          ubi="10"
          maxpop="1,000,000"
          region="0"
          location="0"
          citizen={activeCitizen}
          citizenship={citizenLocation}>

          </LocationPage> 
      </div> 

      <div className={"city city-terrara--" + locale}>
        <LocationPage 
          web3={web3} 
          connected={connected} 
          isActive={isActive__City_Terrara}
          title="Terrara"
          ubi="12" 
          maxpop="500,000"
          region="0"
          location="1"
          citizen={activeCitizen}
          citizenship={citizenLocation}>
          <div className="description">
            <p>Terrara is the agricultural center of Deliland located in Lauren County. 
            </p><p>
            Citizens of Terrara can grow ingredients that other citizens cannot.
            </p>
          </div>
          </LocationPage> 
      </div> 

      <div className={"city city-shio--" + locale}>
        <LocationPage 
          web3={web3} 
          connected={connected} 
          isActive={isActive__City_Shio}
          title="Shio City"
          ubi="3"
          maxpop="1,000,000"
          region="1"
          location="0"
          citizen={activeCitizen}
          citizenship={citizenLocation}>
          <div className="description">
          <p>Shio City is the largest city in the Shio region. 
          It sits in the Northsalt Valley.  
          Shio City is a national fitness center with a significant influence on sports.
            </p><p>
           Citizens of Shio City have access to the Shio City Gym, where they can train their Strength, Speed, and Stamina.
            </p>
          </div>
          </LocationPage> 
      </div> 

      <div className={"city city-toray--" + locale}>
        <LocationPage 
          web3={web3} 
          connected={connected} 
          isActive={isActive__City_Toray}
          title="Toray"
          ubi="2"
          maxpop="500,000"
          region="1"
          location="1"
          citizen={activeCitizen}
          citizenship={citizenLocation}>
          <div className="description">
            <p>Toray is the scientific center of Deliland located in the Shio Region. 
            </p><p>
            Citizens of Toray can increase their Wisdom at the College of Arts and Sciences.
            </p>
          </div>
          </LocationPage> 
      </div>
      <div className={"city city-nibo--" + locale}>
        <LocationPage 
          web3={web3} 
          connected={connected} 
          isActive={isActive__City_Nibo}
          title="Nibo"
          ubi="4"
          maxpop="8,000,000"
          region="2"
          location="0"
          citizen={activeCitizen}
          citizenship={citizenLocation}>
          <div className="description">
            <p>Nibo is the Capital of Deliland, located within The Arbor.
             Situated along the Niborian River, deep within the Great Forest, Nibo is a national cultural and political center with a significant influence on politics, art, and fashion.
            </p><p>
            Citizens of Nibo have access to the Niborian College of Engineering where they can study to increase their Dexterity.
            </p>
          </div>
        </LocationPage> 
      </div> 
      <div className={"city city-arbo--" + locale}>
        <LocationPage 
          web3={web3} 
          connected={connected} 
          isActive={isActive__City_Arbo}
          title="Arbo"
          ubi="20"
          maxpop="12,000,000"
          region="2"
          location="1"
          citizen={activeCitizen}
          citizenship={citizenLocation}> 
          <div className="description">
            <p>Arbo is a major city in The Arbor.
             It sits just within the eastern border, along the Niborian River.
              Arbo is a national financial center with a significant influence on financial markets and productivity.
            </p><p>
            Citizens of Arbo have access to the Arbo Markets, providing advanced market-making features and strategies.
            </p>
          </div>
        </LocationPage> 
      </div> 


      <div className={"travel-container travel-container--" + isTravelOpen}>
        <h3>Shio Region</h3>
        <div className="travel-buttons">
          <button onClick={travel("salt")} className="toggle-btn">
            Salt Mines
          </button>
          <button onClick={travel("shio")} className="toggle-btn">
            Shio City
          </button>
          <button onClick={travel("toray")} className="toggle-btn">
            Toray
          </button>
        </div>
        <h3>Lauren County</h3>
        <div className="travel-buttons">
          <button onClick={travel("lake")} className="toggle-btn">
            Lake Lauren
          </button>
          <button onClick={travel("farms")} className="toggle-btn">
            Farms of Terrara 
          </button>
          <button onClick={travel("refine")} className="toggle-btn">
            Industrial Zone
          </button>
          <button onClick={travel("lauren")} className="toggle-btn">
            City of Lauren
          </button>
          <button onClick={travel("terrara")} className="toggle-btn">
            Terrara (City)
          </button>
        </div>
        <h3>The Arbor</h3>
        <div className="travel-buttons">
          <button onClick={travel("bank")} className="toggle-btn">
            Deliland National Bank
          </button>
          <button onClick={travel("grid")} className="toggle-btn">
            Energy Grid
          </button>
          <button onClick={travel("ugu")} className="toggle-btn">
            Ugu's Exchange
          </button> 
          <button onClick={travel("nibo")} className="toggle-btn">
            Nibo (Deliland Capital)
          </button>
          <button onClick={travel("arbo")} className="toggle-btn">
            Arbo (City)
          </button>
          <a href="https://acres.deliland.io" target="_blank">Old ACRE Farm</a>
          <a href="https://animalfarm.deliland.io" target="_blank">Old ANIMAL Farm</a>
          <a href="https://yomopu.maxus.blog" target="_blank">Old YOMOPU Farm</a>
          <a href="https://citizens.deliland.io" target="_blank">Citizens</a>
        </div>

      </div>
      <Footer />
    </div>

  );
}

