import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';
import saltimg from '../gdeli/salt.png';
import axe from '../gdeli/pickaxe.png';
import { BigNumber } from '@ethersproject/bignumber';
import { saltABI } from './SaltTools.js'
import { chainMap, enforceChain } from '../tools/ChainTools.js'
import { useInterval } from '../tools/UseInterval.js'
import bg from '../gdeli/saltmines.png'
import { RpgMap } from './rpgmap.js'

const ONBOARD_TEXT = 'Click here to install MetaMask!';
const CONNECT_TEXT = 'Connect';
const CONNECTED_TEXT = 'Connected';
const ADDY_SALT = '0x681a02302213c09171ddd45232cc5652e95ccc83';

//const MINING_TEXT = 'Begin Mining SALT';
//const IS_MINING_TEXT = 'You are Mining SALT';

//const CLAIM_TEXT = 'Collect Mined SALT';

//https://www.joshwcomeau.com/snippets/react-hooks/use-interval/




export const SaltMine = (props) => {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  

  const [isCheckingAcc, setIsCheckingAcc] = React.useState(false);
  const [totalAcc, setTotal] = React.useState(0);
  const [isMining, setIsMining] = React.useState(false);

  const [MiningClass, setMiningClass] = React.useState("MineSalt");

  var web3 = props.web3

  let contract = new web3.eth.Contract(saltABI, ADDY_SALT);

  const [internalClock, setInternalClock] = React.useState(0);

  const checkAccumulated = () => {
    contract.methods.accumulated(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress}, 
            (error, totalAccumulated) => {
              setTotal(totalAccumulated / 10 ** 18);
    });
  }

  React.useEffect(()=> {
    if (props.connected) {
      contract.methods.accumulated(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress}, (error, totalAccumulated) => {
        setTotal(totalAccumulated / 10 ** 18);
      });
    }
  }, [props.connected])
    
      
  

  React.useEffect(() => {
    if (totalAcc > 0 && !isMining) {
      setIsMining(true)
    } 
  }, [totalAcc])

  React.useEffect(() => {
    if ( isMining ) {
      setMiningClass("MineSalt MineSalt--disabled");
    }
  }, [isMining])

  const claimOnClick = () => {
    if (props.connected) {
      
        let walletAddress = window.ethereum.selectedAddress;
        contract.methods.claim()
          .send({from: walletAddress},
            (error, claimQty) => { 
              checkAccumulated()
        

            }
            )
      
    }
  };

  const mineOnClick = () => {
    if (props.connected) {
     
        let walletAddress = window.ethereum.selectedAddress;
        contract.methods.beginMining()
          .send({from: walletAddress},
            (error, rightNow) => {
              setIsMining(true);
              
            }
            )
      
    }
  };

  const letItRip = () => {

    checkAccumulated();

    if ( totalAcc > 0 && !isMining) {
      setMiningClass("MineSalt MineSalt--disabled");
      setIsMining(true);
    } else {
      setMiningClass("MineSalt");
      setIsMining(false);
    }
  }

  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(()=> {
    if (props.isActive) {
      letItRip()
    } 
  },[props.isActive])
  


  return (
    <div className={"salt-container connected--" + props.connect}>
      
      <h2>Shio Salt Mines</h2>
      <p>Just outside of Shio City, the Salt Miners Union mines all of the SALT consumed in Deliland.</p>
      
      <div className={MiningClass}>
        <p>You can join them.</p>
        <button 
          className="salt-button salt-button--mine"
          onClick={mineOnClick}
          data-id="beginMining - transact (not payable)" 
          title="beginMining - transact (not payable)">
            <img src={axe} /> <span>begin Mining</span>
        </button>
        <p>beginMining only needs to be triggered once per wallet.</p>
      </div>
      
      

      <div className="ClaimSalt">
        <p>As a contracted miner, you can come back to the salt mines in Shio whenever you need some SALT.</p>
        <button 
          className="salt-button salt-button--claim"
          onClick={claimOnClick}
          data-id="claim - transact (not payable)" 
          title="claim - transact (not payable)">
            <img src={saltimg} /><span>{"Collect " + numeral(totalAcc).format("0,0.000") + " SALT"}</span>
        </button>
      </div>

      <p>Each miner mines at a rate of <strong>24 SALT per day</strong>.</p>
      <p>SALT accumulates continuously and eternally.</p>
      
      

    </div>

  );
}

