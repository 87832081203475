import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { DashboardSideElement } from './dash.js'

import { BigNumber } from '@ethersproject/bignumber';

import { chainMap, enforceChain } from '../tools/ChainTools.js'
import { useInterval } from '../tools/UseInterval.js'

import { RpgMap } from './rpgmap.js'

import { waterABI } from './WaterTools.js'


//import bg from './saltmines.png'

//import saltimg from '../gdeli/salt.png';
//import axe from '../gdeli/pickaxe.png';

const ONBOARD_TEXT = 'Click here to install MetaMask!';
const CONNECT_TEXT = 'Connect';
const CONNECTED_TEXT = 'Connected';
const ADDY_SALT = '0x681a02302213c09171ddd45232cc5652e95ccc83';
const ADDY_WTR = '0xD802dF71F737F9C687c5d389FD6AC421d0666f10';





const lakeToggle_CLOSED = "lake--closed";


export const Lake = (props) => {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

 
  const [isDisabled, setDisabled] = React.useState(false);


  //app has two states
  //raining / not raining
  const [isRaining, setRaining] = React.useState(0);

  const [timeTilStorm, setTimeTilStorm] = React.useState(0);

  const [lakeVol, setLakeVol] = React.useState(0);
  const [lakeTakePrice, setLakeTakePrice] = React.useState(0);

  const [canTakeFromLake, setTakeFromLake] = React.useState(false);

  const [lakeToggle, setLakeToggle] = React.useState(lakeToggle_CLOSED)


  //an address can have
  // a balance - read directly..
  // a placed collector
  const [isCollecting, setCollecting] = React.useState(false);
  const [collectedAmt, setCollectedAmt] = React.useState(0);

  const [pickupDisabled, setPickupDisabled] = React.useState(false);
  const [placeDisabled, setPlaceDisabled] = React.useState(false);



  var web3 = props.web3

  // TODO replace with WATER address.
  let contract = new web3.eth.Contract(waterABI, ADDY_WTR);



  function timeTilStorm_Raining () {
    contract.methods.timeUntilStorm().call(
        {}, 
        (error, tUntilStorm) => {
          setTimeTilStorm(
              "Time Until Storm Ends: " + numeral((tUntilStorm / 10 ** 18 - 8*3600)/3600).format("0,0.000") + " Hours")})

  }

  function timeTilStorm_NotRaining () {
    contract.methods.timeUntilStorm().call(
              {}, 
              (error, tUntilStorm) => {
                setTimeTilStorm(
                  "Time Until Next Storm: " + numeral(tUntilStorm / (3600 * 10 ** 18)).format("0,0.000") + " Hours");
              })
  }

  function cm_getLakeVolume() {
    contract.methods.getLakeVolume().call({}, (error, lakeVolume) => {
          setLakeVol(numeral(lakeVolume / 10 ** 18).format("0,0"));
        })
  }



  React.useEffect( () => {
      cm_getLakeVolume()
      contract.methods.isItRaining().call({}, (error, raining ) => {
        if (raining == 1) {
          setRaining("Raining") 

        } else {
          setRaining("Clear Skies")
        }
      })
  }, [timeTilStorm])

  React.useEffect( () => {
    if (isRaining == "Raining") {
      setPickupDisabled(true);
      setPlaceDisabled(true);
      setTakeFromLake(false);
    } else {
      setTakeFromLake(true);
    }

  }, [isRaining])

  function cm_checkCollectedRain () {
    contract.methods.checkCollectedRain().call({from: window.ethereum.selectedAddress})
                .then( (collected) => {
                
                  setCollectedAmt(numeral(collected / 10 ** 18).format("0,0"));
                  setPickupDisabled(false);
                  setPlaceDisabled(true);
                })
                .catch((err) => {
                  if (isRaining == "Clear Skies") {
                    setPlaceDisabled(false);
                    setPickupDisabled(true);
                  }
                  
                })
  }

const letItRip = () => {

      contract.methods.isItRaining().call({}, (error, raining ) => {
          if (raining == 1) {
            setRaining("Raining")
            timeTilStorm_Raining();

            setTakeFromLake(true);
            cm_getLakeVolume();
            setPickupDisabled(true);
            setPlaceDisabled(true);
            cm_checkCollectedRain();
          } else {
            setRaining("Clear Skies")
            timeTilStorm_NotRaining();
            setTakeFromLake(false);

              cm_getLakeVolume();
          
              
              cm_checkCollectedRain();

                
              }});
   
       

        contract.methods.getLakeTakePrice().call({}, (error, takePrice) => {
          setLakeTakePrice( numeral(takePrice / 10 ** 18).format("0,0.0") );
        })

    
}





  const clickTakeFromLake = () => {
     if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      let walletAddress = window.ethereum.selectedAddress;


      contract.methods.getLakeTakePrice().call({}, (error, takePrice) => {
      

        contract.methods.takeWaterFromLake()
          .send(
            {to: ADDY_WTR,
            from: walletAddress,
            value: takePrice * 10},
            (error, taken) => {
              contract.methods.getLakeVolume().call({}, (error, lakeVolume) => {
                setLakeVol(numeral(lakeVolume / 10 ** 18).format("0,0.00"));
              })
            })
      })
    }
  }
  

  const clickPickup = () => {
    if (props.connected) {
      let walletAddress = window.ethereum.selectedAddress;
      contract.methods.pickupRainCollector().send({from: walletAddress}, (error, claimed) => {
        setPickupDisabled(true)
        setPlaceDisabled(false)
        setCollecting(false)
      })
    }

  }
  const clickPlace = () => {
    if (props.connected) {
      let walletAddress = window.ethereum.selectedAddress;
      contract.methods.placeRainCollector().send({from: walletAddress}, (error, claimed) => {
        setPickupDisabled(false)
        setPlaceDisabled(true)
        setCollecting(true)
      })
    }

  }

  const toggleLakeOpen = () => {
    if (lakeToggle == lakeToggle_CLOSED) {
      setLakeToggle("");
    } else {
     
    }
  }

  const toggleLakeClosed = () => {
    if (lakeToggle == lakeToggle_CLOSED) {

    } else {
      setLakeToggle(lakeToggle_CLOSED);
    }
  }

  React.useEffect(()=> {
    if (props.isActive) {
      letItRip()
    } 
  },[props.isActive])


  return (
    <div className={"App-wrapper " + isRaining + " connected--" + props.connected}>

     

     <h2><span>Lake Lauren</span></h2>
      
      <div className="world">
      
        <p>Lake Lauren is the largest body of freshwater in Deliland. With regular 3 hour long storms occuring every 8 hours, people from all over Deliland gather here to collect water.</p> 
        <div className="weather">
          <div className ="weather__raining">Current Weather: <span>{ isRaining }</span></div>
          <div className ="weather__timeTilStorm">{ timeTilStorm }</div>
        </div>
        
        <div className = "collecting">
          <div className="checkCollected">
          {
            <span>You have 
              <span class="Display-larger">{collectedAmt}</span> 
              <strong>gH20</strong> in your collector
            </span>
          }
          </div>
          <button 
            className="pickupRainCollector"
            disabled={pickupDisabled}
            onClick={clickPickup}>Pick Up Rain Collector</button>
          <button 
            className="placeRainCollector"
            disabled={placeDisabled}
            onClick={clickPlace}>Place Rain Collector</button>
            <div className="deli-container">
            <p>Water accumulates in collectors at a rate of 50 gH20 per Storm</p>
            <p>Collectors can hold a maximum of 100 gH20.</p>
            <p>Collectors cannot be placed or picked up while it is raining.</p>
            </div>
        </div>
        <DashboardSideElement
          isToggly={true}
          toggleBtn={
            <div><span className="Display">{lakeVol}</span><strong> gH20</strong><p>Lake Lauren Water Supply</p></div>
          }
          addClass="lake"
          >
        
         
          <div className="lake__stats">
            <ul>
              <li>Only take water from the Lake as a LAST RESORT!!</li>
              <li>Current price per gH20 to take from lake: 
                <span className="Display-larger">{lakeTakePrice}</span> 
                <strong> FTM</strong></li>
              <li>"Take from Lake" transfers 10 gH20 to the user for: 
                <span className="Display-larger">{lakeTakePrice * 10}</span> 
                <strong> FTM</strong></li>
              <li>Rain Accumulates in Lauren Lake at a rate of 
                <span className="Display-larger">1,000</span> <strong>gH20 per Storm</strong>
              </li>
              <li>Water evaporates from and flows out of Lauren Lake at a rate of 
                <span className="Display-larger">1,000</span> <strong>gH20 per Day</strong> 
              </li>
              <li>
                Water cannot be taken from the Lake while it is raining.
              </li>
            </ul>
            
            
          </div>
          <button 
            className="takeFromLake" 
            disabled={canTakeFromLake}
            onClick={clickTakeFromLake}>Take gH20 from Lake</button>
            

        </DashboardSideElement>
        
      </div>
     
        
    </div>

  );
}

