// Author: Ianoda aka Maxus -- maxus.blog

import React from 'react';
import Web3 from 'web3'; 
import numeral from 'numeral';
import { BigNumber } from '@ethersproject/bignumber';

import { InputView } from './inputview.js'
import { formatTokenBalance, searchAddress, fromTokenNameTo, fromTokenNameToDecimals, fromTokenNameToAddress } from '../tools/tokenUtilities.js'

import { chainMap, enforceChain } from '../tools/ChainTools.js';
import { contractConfigs, FarmgodCore } from './farmgod-core.js';
import { Provider } from './energyprovider.js'

// MATH STUFF
const dec9 = BigNumber.from(10).pow(9)
const CHEF = contractConfigs["chef"]["address"]
 
function handleChainChange(chainId) {
      window.location.reload();
    }


export const EnergyGrid = (props) => {

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

 
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  // this is a reference to the input field
  const theInputRef = React.createRef();

  // this state manages the display of the input overlay
  const [theInputTOGGLE, setTheInputTOGGLE] = React.useState(false);
  const [theTargetPool, setTheTargetPool] = React.useState(0)

  // this function manages the toggling of theInputTOGGLE state
  const toggleInput = () => {
    if (theInputTOGGLE) {
      setTheInputTOGGLE(false)
    } else {
      setTheInputTOGGLE(true)
    }
  }

  // this state manages the intent of the input overlay
  // it should be set to false initially,
  // and then set to either "add" or "remove"
  const [theInputINTENT, setTheInputINTENT] = React.useState(false);

  // this is the web3 instance used throughout the dApp
  var web3 = props.web3


//  END SCRIPT

  var provider = new Provider(web3, theInputRef)
  var energ = new web3.eth.Contract(contractConfigs["energ"]["abi"], FarmgodCore["energ"]["address"])
  var penerg = new web3.eth.Contract(contractConfigs["penerg"]["abi"], FarmgodCore["penerg"]["address"])

  
  const [balanceOfENERg,setBalanceOfENERg] = React.useState(0);
  const [balanceOfpENERg,setBalanceOfpENERg] = React.useState(0);

  const [dbalanceOfENERg,setdBalanceOfENERg] = React.useState("");
  const [dbalanceOfpENERg,setdBalanceOfpENERg] = React.useState("");

  
  React.useEffect( () => {
    setdBalanceOfENERg(formatTokenBalance(9,balanceOfENERg))
  }, [balanceOfENERg])
  
  React.useEffect( () => {
    setdBalanceOfpENERg(formatTokenBalance(9,balanceOfpENERg))
  }, [balanceOfpENERg])
  

  const getBalance = (token) => {
    if (token == 0) {
      //gdeli.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfGDeli(res))
    } else if (token == 1) {
      energ.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfENERg(res))
    } else if (token == 2) {
      //mgdeli.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfMGDeli(res))
    } else if (token == 3) {
      penerg.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfpENERg(res))
    } else if (token == 4) {
      //acre.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfAcre(res))
    } else if (token == 5) {
      //ugu.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfUgu(res))
    } else if (token == 6) {
      //liggies.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfLiggies(res))
    } else if (token == 7) {
      //animal.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfAnimal(res))
    } else if (token == 8) {
      //wheat.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfWheat(res))
    } else if (token == 9) {
      //cane.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfCane(res))
    } else if (token == 10) {
      //seed.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfSeed(res))
    } else if (token == 11) {
      //salt.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfSalt(res))
    } else if (token == 12) {
      //water.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfWater(res))
    } else if (token == 13) {
      //yomopu.methods.balanceOf(window.ethereum.selectedAddress).call().then((res) => setBalanceOfYomopu(res))
    }
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's wallet balance of the staking token
  const [balanceOfTarget, setBalanceOfTarget] = React.useState(0);
  const smartSetBalanceOfTarget = (balance) => {
    console.log(balance)
    setBalanceOfTarget(balance)
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's allowance (to the CHEF) of the staking token
  const [allowanceOfTarget, setAllowanceOfTarget] = React.useState(0);
  const smartSetAllowanceOfTarget = (allowance) => {
    console.log(allowance)
    setAllowanceOfTarget(allowance)
  }

  // onClick function for approving a given amount of the staking token
  const onClick_Approve = () => {
    setIsPending(true)
    provider.triggerApproval( 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
  }

  // -- end of Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  // onClick function factory for building appropriate input overlay
  const openInputViewAndDoStuff = (targetPool, intent = "add") => {

      return () => {

        toggleInput()
        setTheTargetPool(targetPool)
        setTheInputINTENT(intent)
        smartSetBalanceOfTarget(balanceOfpENERg)
        provider.checkAllowance((allowance) => smartSetAllowanceOfTarget(allowance))

      }
   
  }

   const setInputRefToMAX = () => {
    if (theInputINTENT == "remove") {
      theInputRef.current.value = formatTokenBalance(9, balanceOfpENERg, false)
    } else {
      theInputRef.current.value = formatTokenBalance(9, balanceOfTarget, false)
    }
    
  }

  // onClick function for depositing
  const triggerDeposit = () => {
    setIsPending(true)
    
      provider.depositAmount(
      theTargetPool,
      () => {
        setIsPending(false)
        toggleInput()
        getPools()
        getUserInfo()
        getBalance(3)
        }    
      )
    
    
  }



  

  const triggerWithdraw = () => {
    setIsPending(true)
    provider.withdrawAmount(
      theTargetPool,
      () => {
        setIsPending(false)
        toggleInput()
        getPools()
        getUserInfo()
        getBalance(3)
        }    
      )
  }


  const [poolAmount, setPoolAmount] = React.useState(0)
  

  const [pool0, setPool0] = React.useState([])
  const [dpool0_rewardToken, setdpool0_rewardToken] = React.useState("")
  const [dpool0_rewardPerSecond, setdpool0_rewardPerSecond] = React.useState("")
  const [dpool0_totalStaked, setdpool0_totalStaked] = React.useState("")
  const [upool0, setuPool0] = React.useState([])
  const [dupool0_userStaked,setdupool0_userStaked] = React.useState("")
  const [pending0, setPending0] = React.useState(0)
  const [dpending0, setdPending0] = React.useState("")


  React.useEffect(() => {
    if (pool0.RewardToken) {
    setdpool0_rewardToken(searchAddress(pool0.RewardToken)) 
    setdpool0_rewardPerSecond(formatTokenBalance(18, pool0.RewardPerSecond))
    setdpool0_totalStaked(formatTokenBalance(9, pool0.pENERgStakedAmount))
  }
  }, [pool0])

  React.useEffect(() => {
    if ( upool0.amount ) {
      setdupool0_userStaked(formatTokenBalance(9, upool0.amount))
    }
    
  }, [upool0])

  React.useEffect(() => {
    setdPending0(formatTokenBalance(18, pending0))
  }, [pending0])

// -- pool 1 --------------------------------------------------------------------------------
  const [pool1, setPool1] = React.useState([])
  const [dpool1_rewardToken, setdpool1_rewardToken] = React.useState("")
  const [dpool1_rewardPerSecond, setdpool1_rewardPerSecond] = React.useState("")
  const [dpool1_totalStaked, setdpool1_totalStaked] = React.useState("")
  const [upool1, setuPool1] = React.useState([])
  const [dupool1_userStaked,setdupool1_userStaked] = React.useState("")
  const [pending1, setPending1] = React.useState(0)
  const [dpending1, setdPending1] = React.useState("")


  React.useEffect(() => {
    if (pool1.RewardToken) {
    setdpool1_rewardToken(FarmgodCore[searchAddress(pool1.RewardToken)]["icon"].default) 
    setdpool1_rewardPerSecond(formatTokenBalance(18, pool1.RewardPerSecond))
    setdpool1_totalStaked(formatTokenBalance(9, pool1.pENERgStakedAmount))
  }
  }, [pool1])

  React.useEffect(() => {
    if (upool1.amount) {
    setdupool1_userStaked(formatTokenBalance(9, upool1.amount))
  }
  }, [upool1])

  React.useEffect(() => {
    setdPending1(formatTokenBalance(18, pending1))
  }, [pending1])

  // -- pool 2 --------------------------------------------------------------------------------
  const [pool2, setPool2] = React.useState([])
  const [dpool2_rewardToken, setdpool2_rewardToken] = React.useState("")
  const [dpool2_rewardPerSecond, setdpool2_rewardPerSecond] = React.useState("")
  const [dpool2_totalStaked, setdpool2_totalStaked] = React.useState("")
  const [upool2, setuPool2] = React.useState([])
  const [dupool2_userStaked,setdupool2_userStaked] = React.useState("")
  const [pending2, setPending2] = React.useState(0)
  const [dpending2, setdPending2] = React.useState("")


  React.useEffect(() => {
    if (pool2.RewardToken) {
      console.log(FarmgodCore[searchAddress(pool2.RewardToken)]["icon"])
    setdpool2_rewardToken(FarmgodCore[searchAddress(pool2.RewardToken)]["icon"].default) 
    setdpool2_rewardPerSecond(formatTokenBalance(18, pool2.RewardPerSecond))
    setdpool2_totalStaked(formatTokenBalance(9, pool2.pENERgStakedAmount))
  }
  }, [pool2])

  React.useEffect(() => {
    if (upool2.amount) {
    setdupool2_userStaked(formatTokenBalance(9, upool2.amount))
  }
  }, [upool2])

  React.useEffect(() => {
    setdPending2(formatTokenBalance(18, pending2))
  }, [pending2])

  const getPools = () => {
    provider.poolInfo(0, (info) => {
      console.log(info)
      setPool0(info)
    })
    provider.poolInfo(1, (info) => {
      console.log(info)
      setPool1(info)
    })
    provider.poolInfo(2, (info) => {
      console.log(info)
      setPool2(info)
    })
    
  }
  const getUserInfo = () => {
    console.log("getting user info")
    provider.userInfo(0, (uinfo) => {
      console.log(uinfo)
      setuPool0(uinfo)
      provider.pendingRewards(0, (pend) => {
        setPending0(pend)
      })
    })
    provider.userInfo(1, (uinfo) => {
      console.log(uinfo)
      setuPool1(uinfo)
      provider.pendingRewards(1, (pend) => {
        setPending1(pend)
      })
    })
    provider.userInfo(2, (uinfo) => {
      console.log(uinfo)
      setuPool2(uinfo)
      provider.pendingRewards(2, (pend) => {
        setPending2(pend)
      })
    })
  }

  const harvest = (pId) => {
    return () => {
      setIsPending(true)
    
      provider.harvest(
      pId,
      () => {
        setIsPending(false)
        getUserInfo()
        }    
      )
    }
  }

  const letItRip = () => {
    getBalance(1)
    getBalance(3)
    getPools()
    getUserInfo()
  }

  React.useEffect(
    () => {
      (props.isActive) ? letItRip() : console.log("nothing energy grid")
    }, [props.isActive]
    )


  return (
    <div className={"App"}>
    <h2>Deliland Energy Grid</h2>
      
      <div className="App-core">
      <p>The Deliland Energy Grid spans the entirety of Deliland; however, most of the energy production occurs in The Arbor along the border of Lauren County.</p>
      <p>Here at the Energy Grid headquarters, citizens can earn rewards for staking their pENERg.</p>
      <p>While staking rewards may be enticing, all citizens should make sure they hold some pENERg in their wallet, however small the amount.</p>
        <div className="App-userBalances">
          
          <div>
            <img src={FarmgodCore["energ"]["icon"]["default"]} className="token-icon" alt="energ" /> 
            <span>{dbalanceOfENERg}</span>
          </div>
          <div>
            <img src={FarmgodCore["penerg"]["icon"]["default"]} className="token-icon" alt="penerg" /> 
            <span>{dbalanceOfpENERg}</span>
          </div>
          
        </div>

        <div className="penerg-pool">
          <div className="reward">Emits {dpool0_rewardPerSecond} <img className="tokie" src={FarmgodCore["bpt-graw"]["icon"].default} /><span className="mini">per second</span></div>
          <div className="totalstaked">Total Stake: {dpool0_totalStaked} <img className="tokie" src={FarmgodCore["penerg"]["icon"].default} /></div>
          <div className="userstaked">Your Stake: {dupool0_userStaked} <img className="tokie" src={FarmgodCore["penerg"]["icon"].default} /></div>
          <div className="pendingharvest">Pending Harvest: {dpending0}</div>
          <button className="btn" onClick={openInputViewAndDoStuff(0,"add")}>Deposit</button>
          <button className="btn" onClick={openInputViewAndDoStuff(0,"remove")}>Withdraw</button>
          <button className="btn" onClick={harvest(0)}>Harvest</button>
        </div>

        <div className="penerg-pool">
          <div className="reward">Emits {dpool1_rewardPerSecond} <img className="tokie" src={dpool1_rewardToken} /> <span className="mini">per second</span></div>
          <div className="totalstaked">Total Stake: {dpool1_totalStaked} <img className="tokie" src={FarmgodCore["penerg"]["icon"].default} /></div>
          <div className="userstaked">Your Stake: {dupool1_userStaked} <img className="tokie" src={FarmgodCore["penerg"]["icon"].default} /></div>
          <div className="pendingharvest">Pending Harvest: {dpending1}</div>
          <button className="btn" onClick={openInputViewAndDoStuff(1,"add")}>Deposit</button>
          <button className="btn" onClick={openInputViewAndDoStuff(1,"remove")}>Withdraw</button>
          <button className="btn" onClick={harvest(1)}>Harvest</button>
        </div>

        <div className="penerg-pool">
          <div className="reward">Emits {dpool2_rewardPerSecond} <img className="tokie" src={dpool2_rewardToken} /> <span className="mini">per second</span></div>
          <div className="totalstaked">Total Stake: {dpool2_totalStaked} <img className="tokie" src={FarmgodCore["penerg"]["icon"].default} /> </div>
          <div className="userstaked">Your Stake: {dupool2_userStaked} <img className="tokie" src={FarmgodCore["penerg"]["icon"].default} /> </div>
          <div className="pendingharvest">Pending Harvest: {dpending2}</div>
          <button className="btn" onClick={openInputViewAndDoStuff(2,"add")}>Deposit</button>
          <button className="btn" onClick={openInputViewAndDoStuff(2,"remove")}>Withdraw</button>
          <button className="btn" onClick={harvest(2)}>Harvest</button>
        </div>

      </div>

      <InputView 
        targetPool={theTargetPool}
        toggle={theInputTOGGLE}
        intent={theInputINTENT}
        theInputRef={theInputRef}
        setInputRefToMAX={setInputRefToMAX}
        poolBalance={(theTargetPool == 0) ? dupool0_userStaked : (theTargetPool == 1) ? dupool1_userStaked : dupool2_userStaked}
        balance={dbalanceOfpENERg}
        allowance={formatTokenBalance(9, allowanceOfTarget)}
        toggleInput={toggleInput}
        onClick_Approve={onClick_Approve}
        triggerDeposit={triggerDeposit}
        triggerWithdraw={triggerWithdraw} />

      <div className={"pending pending--" + isPending}>Pending Transaction</div>

 
   </div>
  );
}


