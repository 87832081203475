import React from 'react' 
import { liveCitizens } from './farmgod-core.js'



export const LocationPage = (props) => {
	const [isPending, setIsPending] = React.useState(false)
	const [pendingMessage, setPendingMessage] = React.useState("Pending")
	var x

	const ClaimUBIButton = (props)=>(<button onClick={claimUBI}>
	Claim UBI
	</button>)

	const claimUBI = () => {
		var y = new props.web3.eth.Contract(
				liveCitizens["citizenship"]["abi"], 
				liveCitizens["citizenship"]["address"])
		setIsPending(true)
		setPendingMessage("Claiming UBI")
		y.methods.claimUBI(Number(props.citizen))
					.send({from: window.ethereum.selectedAddress})
					.then((res) => {
						setIsPending(false)
					})
	}
	const increaseStat = (num) => {
		return () => {
			var y = new props.web3.eth.Contract(
				liveCitizens["citizen"]["abi"], 
				liveCitizens["citizen"]["address"])
			setIsPending(true)
			if (num == 0) {
				setPendingMessage("Lifting Weights")
				console.log(props.citizen, "in increaseStat")
				y.methods.statActivity_increaseStrength(Number(props.citizen))
					.send({from: window.ethereum.selectedAddress})
					.then((res) => {
						setIsPending(false)
					})
			} else if (num == 1) {
				setPendingMessage("Sprinting")
				console.log(props.citizen, "in increaseStat")
				y.methods.statActivity_increaseSpeed(Number(props.citizen))
					.send({from: window.ethereum.selectedAddress})
					.then((res) => {
						setIsPending(false)
					})
			} else if (num == 2) {
				setPendingMessage("Running Long Distance")
				console.log(props.citizen, "in increaseStat")
				y.methods.statActivity_increaseStamina(Number(props.citizen))
					.send({from: window.ethereum.selectedAddress})
					.then((res) => {
						setIsPending(false)
					})
			} else if (num == 3) {
				setPendingMessage("Studying Engineering")
				console.log(props.citizen, "in increaseStat")
				y.methods.statActivity_increaseDexterity(Number(props.citizen))
					.send({from: window.ethereum.selectedAddress})
					.then((res) => {
						setIsPending(false)
					})
			} else if (num == 4) {
				setPendingMessage("Studying Arts and Sciences")
				console.log(props.citizen, "in increaseStat")
				y.methods.statActivity_increaseWisdom(Number(props.citizen))
					.send({from: window.ethereum.selectedAddress})
					.then((res) => {
						setIsPending(false)
					})
			} else {
				setPendingMessage("Studying Leadership")
				console.log(props.citizen, "in increaseStat")
				y.methods.statActivity_increaseCharisma(Number(props.citizen))
					.send({from: window.ethereum.selectedAddress})
					.then((res) => {
						setIsPending(false)
					})
			}
		}
	}
	// Lauren County
	if (props.region == "0") {
		//City of Lauren
		if (props.location == "0") {
			x = (<div>
				   <h3>Laureni Secret Society</h3>
				   <p>...Coming Soon</p>
				   <ClaimUBIButton />
				</div>)
		// Terrara
		} else {
			x = (<div>
				   <h3>Elite Farms of Terrara</h3>
				   <p>...Coming Soon</p>
				   <ClaimUBIButton />
				</div>)
		}
	// The Shio Region
	} else if (props.region == "1") {
		// Shio City
		if (props.location == "0") {
			x = (<div>
				   <h3>Shio City Gym</h3>
				   <p>Welcome Citizen of Shio City!</p>
				   <p>Make use of your free access to the Shio City Gym.</p>
				   <button onClick={increaseStat(0)}>Train Strength</button>
				   <button onClick={increaseStat(1)}>Train Speed</button>
				   <button onClick={increaseStat(2)}>Train Stamina</button>
				   <p>Training increases your Stat by 1 and can be repeated once every 20 seconds.</p>
				   <p>A Stat Expansion will be released in the near future.</p>
				   <p>It will NOT affect these stats.</p>
				   <ClaimUBIButton />
				</div>)

		// Toray
		} else {
			x = (<div>
				   <h3>Toray College of Arts & Sciences</h3>
				   <p>Welcome Citizen of Toray!</p>
				   <p>Make use of your free access to Toray College of Arts & Sciences.</p>
				   <button onClick={increaseStat(4)}>Train Wisdom</button>
				   <p>Training increases Wisdom by 1 and can be repeated once every 26 weeks.</p>
				   <p>A Stat Expansion will be released in the near future.</p>
				   <p>It will count each +1 Wisdom gained here as +10,000 as was originally intended.</p>
					<ClaimUBIButton />
				</div>)
		}
	// The Arbor
	} else if (props.region == "2") {
		// Nibo
		if (props.location == "0") {
			x = (<div>
				   <h3>Niborian College of Engineering</h3>
				   <p>Welcome Citizen of Nibo!</p>
				   <p>Make use of your free access to the Niborian College of Engineering.</p>
				   <button onClick={increaseStat(3)}>Train Dexterity</button>
				   <p>Training increases Dexterity by 2 and can be repeated once every day.</p>
				   <p>A Stat Expansion will be released in the near future.</p>
				   <p>It will count each +2 Dexterity gained here as +200 as was originally intended.</p> 
					<ClaimUBIButton />
				</div>)
		// Arbo
		} else {
			x = (<div>
				   <h3>Arbo Market Making Terminal</h3>
				   <p>...Coming Soon</p>
				   <ClaimUBIButton />
				</div>)
		}
	// Vita
	} else {
		// Vita
		if (props.location == "0") {
			x = (<div>
				   <h3>Vitarian Leadership School</h3>
				   <p>Welcome Citizen of Vita!</p>
				   <p>Make use of your free access to the Vitarian Leadership School.</p>
				   <button onClick={increaseStat(5)}>Train Charisma</button>
				   <p>Training increases Charisma by 15,000 and can be repeated once every year.</p>
				   <p>A Stat Expansion will be released in the near future.</p>
				   <p>It will NOT affect Charisma.</p>
				   <ClaimUBIButton />
				</div>)
		} else {
			
		}
	}

	const [isCitizen, setIsCitizen] = React.useState(false)
	const getIsCitizen = () => {
		var shouldBe = [Number(props.region),Number(props.location)]
		var is = [Number(props.citizenship[0]), Number(props.citizenship[1])]
		console.log("should be", shouldBe, "in Location Page getIsCitizen")
		console.log("is",is, "in Location Page getIsCitizen")
		if (shouldBe[0] == is[0] && shouldBe[1] == is[1]) {
			setIsCitizen(true)
		} else {
			setIsCitizen(false)
		}
	}

	React.useEffect(()=>{
		if (props.citizenship[0] < 4) {
			getIsCitizen()
		}
	},props.citizenship)
	return(
		<div className="location-page">
			<h3>{props.title}</h3>
			<p>Citizens of {props.title} earn a universal basic income of {props.ubi} UGU per year.</p>
			<p>{props.title} can support {props.maxpop} Citizens.</p>
			
			<div>{props.children}</div>
			<div className={"citizens-only citizens-only--" + isCitizen}>{x}</div>
			<div className={"pending pending--" + isPending}>{pendingMessage}</div>
		</div>
	)
}